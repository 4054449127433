import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { IFilters } from '../../utils/types';
import {
  B2BOrderListItem,
  UserRoles,
  WarehouseB2BStatus,
  useCancelB2BOrderMutation,
  useCompleteB2BOrderMutation,
  useGetB2BOrderListLazyQuery,
  useGetCurrentUserQuery,
} from '../../generated/graphql';
import { useSnackbar } from 'notistack';
import PaginationComponent from '../../components/PaginationComponent/PaginationComponent';
import {
  B2B_SHIPMENT_ORDER_LIST_TABLE_CELLS_ARRAY,
  LOCAL_STORAGE_KEYS,
} from '../../utils/constants';
import TableBodyRequestHandler from '../../components/TableBodyRequestHandler/TableRequestHandler';
import { useTranslation } from 'react-i18next';
import { DEPARTURE_DATE_FILTER, LIMIT_ITEM_PAGE } from '../../utils/helpers';
import FilterSelectWarehouse from '../../components/FilterComponents/FilterSelectWarehouse/FilterSelectWarehouse';
import FilterSelectDepartureDate from '../../components/FilterComponents/FilterSelectDepartureDate/FilterSelectDepartureDate';
import FilterSelectLimitItems from '../../components/FilterComponents/FilterSelectLimitItems/FilterSelectLimitItems';
import { GET_B2B_ORDER_LIST_QUERY } from '../../GraphQL/queries/getB2BOrderList';
import CancelWarehouseShipmentOrderModal from '../../components/Modals/CancelWarehouseShipmentOrderModal';
import FilterInputSearch from '../../components/FilterComponents/FilterInputSerch/FilterInputSerch';
import FilterSelectPaymentStatus from '../../components/FilterComponents/FilterSelectPaymentStatus/FilterSelectPaymentStatus';
import B2bShipmentTableRowComponent from '../../components/B2bShipmentsComponents/B2bShipmentTableRowComponent';
import FilterSelectB2bWarehouseShipmentStatus from '../../components/FilterComponents/FilterSelectB2bWarehouseShipmentStatus/FilterSelectB2bWarehouseShipmentStatus';

const B2bShipments = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const itemsLimit = localStorage.getItem(
    LOCAL_STORAGE_KEYS.ITEMS_LIMIT_SHIPMENTS_ALL_HISTORY,
  );

  const [orders, setOrders] = useState<B2BOrderListItem[] | null>(null);
  const [isOpenConfirmCancel, setIsOpenConfirmCancel] = useState(false);
  const [orderIdToCancel, setOrderIdToCancel] = useState<number | null>(null);
  const [filter, setFilter] = useState<IFilters>({
    paymentStatusId: '',
    search: '',
    page: 1,
    itemsLimit: itemsLimit ? +itemsLimit : LIMIT_ITEM_PAGE,
    warehouseId: '',
    warehouseStatus: '',
  });

  const { data: currentUserData } = useGetCurrentUserQuery();

  const [cancelB2BOrderMutation, { loading: cancelB2BOrderMutationLoading }] =
    useCancelB2BOrderMutation();

  const [
    completeB2BOrderMutation,
    { loading: completeB2BOrderMutationLoading },
  ] = useCompleteB2BOrderMutation({
    onError: (error) => {
      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
  });

  const [getB2BOrderListLazyQuery, { data, loading }] =
    useGetB2BOrderListLazyQuery({
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
    });

  useEffect(() => {
    if (!data?.getB2BOrderList?.rows) return;

    data?.getB2BOrderList?.rows &&
      setOrders(data?.getB2BOrderList?.rows as B2BOrderListItem[]);
  }, [data]);

  useEffect(() => {
    getB2BOrderListLazyQuery({
      variables: {
        filter: {
          offset: (filter.page! - 1) * filter.itemsLimit!,
          limit: filter.itemsLimit,
          search: filter.search,
          ...(filter.dateFilter !== DEPARTURE_DATE_FILTER.ALL_TIME && {
            dateFilter: Number(filter.dateFilter),
          }),
          ...(filter.paymentStatusId && {
            paymentStatusId: filter.paymentStatusId as string,
          }),
          ...(filter.dateFilter !== DEPARTURE_DATE_FILTER.ALL_TIME && {
            dateFilter: Number(filter.dateFilter),
          }),
          warehouseId: Number(filter.warehouseId),
          ...(filter.warehouseStatus && {
            warehouseStatus: filter.warehouseStatus as WarehouseB2BStatus,
          }),
        },
      },
    });
    //eslint-disable-next-line
  }, [filter]);

  const cancelShipmentOrderHandler = (id: number) => {
    cancelB2BOrderMutation({
      variables: { orderId: Number(orderIdToCancel) },
      onCompleted: (data) => {
        if (data) {
          enqueueSnackbar(t('app.success'), { variant: 'success' });
        }
      },
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
      refetchQueries: [GET_B2B_ORDER_LIST_QUERY],
    });
  };

  const handleComplete = (id: number) => {
    completeB2BOrderMutation({
      variables: { orderId: Number(id) },
      onCompleted: (data) => {
        if (data) {
          enqueueSnackbar(t('app.b2bShipmentOrderSuccessfullyCompleted'), {
            variant: 'success',
          });
        }
      },
      refetchQueries: [GET_B2B_ORDER_LIST_QUERY],
    });
  };

  const tableCells = B2B_SHIPMENT_ORDER_LIST_TABLE_CELLS_ARRAY;

  return (
    <>
      <Typography variant='h2' align='center' className={classes.mainTitle}>
        {t('app.b2bShipments')}
      </Typography>
      <div className={classes.filters}>
        <FilterSelectWarehouse
          value={filter.warehouseId as string}
          setFilter={setFilter}
        />
        <FilterSelectDepartureDate
          value={filter.dateFilter as string}
          setFilter={setFilter}
        />
        <FilterSelectB2bWarehouseShipmentStatus
          value={filter.warehouseStatus}
          setFilter={setFilter}
        />
        <FilterSelectPaymentStatus
          value={filter.paymentStatusId as string}
          setFilter={setFilter}
        />
        <FilterSelectLimitItems
          value={filter.itemsLimit as number}
          setFilter={setFilter}
          localStorageItemsLimitKey={
            LOCAL_STORAGE_KEYS.ITEMS_LIMIT_SHIPMENTS_ALL_HISTORY
          }
        />

        <FilterInputSearch setFilter={setFilter} />
      </div>
      <TableContainer component={Paper}>
        <Table arial-label='таблица отправлении'>
          <TableHead>
            {tableCells.map((cell) => (
              <TableCell align='center'>{t(cell)}</TableCell>
            ))}
          </TableHead>
          <TableBody>
            <TableBodyRequestHandler
              dataLength={Number(orders?.length)}
              colSpan={tableCells.length}
              loadingText={t('app.shipments').toLowerCase()}
              loading={loading}
            >
              {orders
                ? orders?.map((order) => {
                    return (
                      <B2bShipmentTableRowComponent
                        key={order.id}
                        order={order}
                        setOrderIdToCancel={setOrderIdToCancel}
                        setIsOpenConfirmCancel={setIsOpenConfirmCancel}
                        isSuperAdmin={
                          currentUserData?.currentUser?.role ===
                          UserRoles.SuperAdmin
                        }
                        handleComplete={handleComplete}
                        completeB2BOrderMutationLoading={
                          completeB2BOrderMutationLoading
                        }
                      />
                    );
                  })
                : null}
            </TableBodyRequestHandler>
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationComponent
        count={data?.getB2BOrderList?.count}
        limit={filter?.itemsLimit as number}
        currentPage={filter.page!}
        setFilter={setFilter}
      />

      <CancelWarehouseShipmentOrderModal
        orderIdToCancel={orderIdToCancel}
        isOpenConfirmCancel={isOpenConfirmCancel}
        setIsOpenConfirmCancel={setIsOpenConfirmCancel}
        setOrderIdToCancel={setOrderIdToCancel}
        cancelWarehouseShipmentOrderHandler={cancelShipmentOrderHandler}
        cancelWarehouseShipmentOrderLoading={cancelB2BOrderMutationLoading}
      />
    </>
  );
};

export default B2bShipments;
