import { DASHBOARD_DEPARTURE_DATE_FILTER } from '../../../../utils/helpers';

export const filterSelectDepartureDateOptions = [
  { title: 'app.period' },

  { title: 'app.today', value: DASHBOARD_DEPARTURE_DATE_FILTER.TODAY },
  { title: 'app.yesterday', value: DASHBOARD_DEPARTURE_DATE_FILTER.YESTERDAY },
  {
    title: 'app.lastNDays',
    nDays: 7,
    value: DASHBOARD_DEPARTURE_DATE_FILTER.LAST_7,
  },
  {
    title: 'app.lastNDays',
    nDays: 30,
    value: DASHBOARD_DEPARTURE_DATE_FILTER.LAST_30,
  },
  {
    title: 'app.dateRange',
    value: DASHBOARD_DEPARTURE_DATE_FILTER.RANGE,
  },
];
