import React from 'react';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { BrowserRouter as Router } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { SnackbarProvider } from 'notistack';
import { ApolloProvider } from '@apollo/client';
import 'moment/locale/ru';
import client from './GraphQL/client';
import Layout from './Layout';
import theme from './assets/theme';
import 'react-phone-number-input/style.css';
import StoreProvider from './utils/store';
import { AppFormProvider } from './context/FormContext';
import './utils/i18n';

function App() {
  return (
    // @ts-ignore
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <SnackbarProvider
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
          >
            <AppFormProvider>
              <CssBaseline />
              <StoreProvider>
                <Router>
                  <Layout />
                </Router>
              </StoreProvider>
            </AppFormProvider>
          </SnackbarProvider>
        </MuiPickersUtilsProvider>
      </ThemeProvider>
    </ApolloProvider>
  );
}

export default App;
