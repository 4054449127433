import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import moment from 'moment';

export const i18nInit = (async () => {
  // eslint-disable-next-line import/no-named-as-default-member
  return i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(HttpApi)
    .init({
      supportedLngs: ['en', 'ru'],
      fallbackLng: 'ru',
      defaultNS: 'ru',
      detection: {
        order: ['localStorage', 'navigator', 'htmlTag'],
      },
      backend: {
        loadPath: '/locales/{{lng}}/translation.json',
      },
      react: { useSuspense: false },
    })
    .then(() => {
      const currentLocale = i18n.language || 'ru';

      import(`moment/locale/${currentLocale}`)
        .then(() => {
          moment.locale(currentLocale);
        })
        .catch(() => {
          moment.locale('ru');
        });
    });
})();
