import React from 'react';
import { MenuItem, Select, TableCell, TableRow } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { getActionOptions } from './options';
import { TRoutes } from '../../utils/helpers';
import { IB2bShipmentTableRowComponent } from './types';
import {
  ActionsItem,
  ConvertB2bWarehouseStatusIdToColorForAdmin,
} from '../../utils/helperComponents';
import { DATE_FORMAT, PAYMENT_ORDER_STATUS_BY_ID } from '../../utils/constants';
import moment from 'moment';

const B2bShipmentTableRowComponent = ({
  order,
  setOrderIdToCancel,
  setIsOpenConfirmCancel,
  isSuperAdmin,
  handleComplete,
  completeB2BOrderMutationLoading,
}: IB2bShipmentTableRowComponent) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const openModalForCancelHandler = () => {
    setOrderIdToCancel(Number(order?.id));
    setIsOpenConfirmCancel(true);
  };

  const onHandleComplete = () => {
    handleComplete(Number(order.id));
  };

  const formattedDate = moment(Number(order?.createdAt)).format(DATE_FORMAT);

  const formattedShipmentDate = new Date(
    order?.shipmentDate as string,
  ).toLocaleDateString();

  const orderIdTitle = order?.barcodeId?.split('.')[0];

  const options = getActionOptions(
    order,
    openModalForCancelHandler,
    isSuperAdmin,
    onHandleComplete,
    completeB2BOrderMutationLoading,
  );

  return (
    <TableRow>
      <TableCell align='center'>
        <Link
          to={`${
            TRoutes.B2B_ORDERS_DETAILS_WITHOUT_ID
          }${order?.id?.toString()}`}
        >
          {orderIdTitle}
        </Link>
        <br />
        {formattedDate}
      </TableCell>
      <TableCell align='center'>
        <Link
          to={`${
            TRoutes.PAYMENT_STATISTICS_PER_USER_WITHOUT_ID
          }${order?.client?.id?.toString()}`}
        >
          {order?.client?.name}
        </Link>
      </TableCell>
      <TableCell align='center'>{order.warehouseName}</TableCell>
      <TableCell align='center'>{order.shipmentPointTitle}</TableCell>
      <TableCell align='center'>{order.productsQty}</TableCell>
      <TableCell align='center'>{order.totalCost}</TableCell>
      <TableCell align='center'>{order.externalOrdeId}</TableCell>
      <TableCell align='center'>{formattedShipmentDate}</TableCell>
      <TableCell align='center'>
        <ConvertB2bWarehouseStatusIdToColorForAdmin
          status={order?.warehouseStatus as string}
        />
      </TableCell>
      <TableCell align='center'>
        {t(PAYMENT_ORDER_STATUS_BY_ID[Number(order?.paymentStatus?.id)])}
      </TableCell>
      <TableCell align='center'>
        <Select value={t('app.action')}>
          <MenuItem
            value={t('app.action')}
            disabled
            style={{ display: 'none' }}
          >
            {t('app.action')}
          </MenuItem>
          {options?.map((option) => (
            <ActionsItem
              {...option}
              key={option.title}
              itemLinkName={classes.actionMenuItem}
              linkClassName={classes.actionLink}
              iconClassName={classes.actionIcon}
              redColorIconClassName={classes.actionIconCancel}
              greenColorIconClassName={classes.actionIconWatch}
              blueColorIconClassName={classes.actionIconSend}
            />
          ))}
        </Select>
      </TableCell>
    </TableRow>
  );
};

export default B2bShipmentTableRowComponent;
