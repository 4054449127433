import client from '../GraphQL/client';
import moment from 'moment';
import { QUERY_DELIVERY_COUNTRIES } from '../GraphQL/queries/getDeliveryCountries';
import {
  LOCAL_STORAGE_KEYS,
  MOVEMENT_OPERATIONS,
  UNIT_STATES,
} from './constants';
import { QUERY_VALIDATE_ADDRESS } from '../GraphQL/queries/validationAddress';
import { t } from 'i18next';
import { WarehouseProductLogOperation } from '../generated/graphql';
import { Link } from 'react-router-dom';

export class TRoutes {
  static MAIN = '/';
  static AUTH_SIGN_IN = '/sign-in';

  static CLIENTS = '/clients';
  static CLIENTS_CREATE = `${TRoutes.CLIENTS}/create`;

  static PARCELS = '/parcels';
  static PARCEL_CONSOLIDATION = `${this.PARCELS}/consolidation`;

  static PARCEL_EDIT = `${this.PARCELS}/edit/:id`;
  static PARCEL_DETAIL = `${this.PARCELS}/:id`;
  static PARCEL_SEND = `${this.PARCELS}/send/:id`;
  static PARCEL_DEPARTURE = `${this.PARCELS}/departure`;
  static PARCELS_FROM_WAREHOUSE = `${this.PARCELS}/fromwarehouse`;
  static PARCELS_FROM_WAREHOUSE_DETAILS = `${this.PARCELS}/fromwarehouse/:id`;
  static PARCELS_FROM_WAREHOUSE_DETAILS_WITHOUT_ID = `${this.PARCELS}/fromwarehouse/`;

  static RETURNS = '/returns';
  static RETURNS_RMA_LIST = `${TRoutes.RETURNS}/rma/list`;
  static RETURNS_RTS_LIST = `${TRoutes.RETURNS}/rts/list`;
  static RETURN_DETAILS = `${TRoutes.RETURNS}/details/:id`;
  static RETURN_DETAILS_WITHOUT_ID = `${TRoutes.RETURNS}/details/`;

  static TRACK = '/track';

  static REPORTS = '/reports';

  static PARCEL_TRACK_WITH_CARRIER = `${this.TRACK}/:carrierCodeParam/:trackNumberParam`;

  static PAYMENT_STATISTICS = '/payment-statistics';
  static PAYMENT_STATISTICS_PER_USER = `${this.PAYMENT_STATISTICS}/:userId`;
  static PAYMENT_STATISTICS_PER_USER_WITHOUT_ID = `${this.PAYMENT_STATISTICS}/`;

  static WAREHOUSE_SEND_DETAILS = '/warehouse/details/:id';
  static WAREHOUSE_SHIPMENT_LIST = '/warehouse/shipment/list';
  static WAREHOUSE_SHIPMENT_DETAILS = '/warehouse/shipment/details/:id';
  static WAREHOUSE_SHIPMENT_DETAILS_WITHOUT_ID = '/warehouse/shipment/details/';
  static WAREHOUSES_LIST = '/warehouses';
  static WAREHOUSES_MANAGERS = '/warehouses/managers';
  static WAREHOUSE = '/warehouse';
  static WAREHOUSE_MY_PRODUCT_DETAILS = '/warehouse/product/:id';
  static WAREHOUSE_MY_PRODUCT_DETAILS_WITHOUT_ID = '/warehouse/product/';
  static WAREHOUSE_ZONES = '/warehouse/zones';
  static WAREHOUSE_CELLS = '/warehouse/cells';
  static WAREHOUSE_RESIDUES = '/warehouse/residues';
  static WAREHOUSE_SWAP_HISTORY = '/warehouse/swap-history';

  static WAREHOUSE_SHIPMENT_FORWARDING_LIST =
    '/warehouse/shipment-forwarding/list';

  static SETTINGS = '/settings';
  static SETTINGS_CALCULATOR = `${this.SETTINGS}/calculator`;

  static UNDECLARED_ARRIVAL_LIST = `${TRoutes.WAREHOUSE}/undeclared-arrival`;
  static UNDECLARED_ARRIVAL_DETAILS = `${TRoutes.WAREHOUSE}/undeclared-arrival/details/:id`;
  static UNDECLARED_ARRIVAL_DETAILS_WITHOUT_ID = `${TRoutes.WAREHOUSE}/undeclared-arrival/details/`;

  static NOTIFICATIONS = `/notifications`;

  static SETTINGS_SENDER_ACCOUNTS = `${TRoutes.SETTINGS}/senders`;
  static SETTINGS_SENDER_ACCOUNTS_CREATE = `${TRoutes.SETTINGS_SENDER_ACCOUNTS}/create/:type`;
  static SETTINGS_SENDER_ACCOUNTS_UPDATE = `${TRoutes.SETTINGS_SENDER_ACCOUNTS}/update/:id`;
  static SETTINGS_B2B_SHIPMENTS_POINTS = `${TRoutes.SETTINGS}/b2b-points`;
  static SETTINGS_B2B_SHIPMENTS_SERVICES = `${TRoutes.SETTINGS}/b2b-services`;

  static B2B_ORDERS = '/b2b';
  static B2B_ORDER_PACKAGING = `${TRoutes.B2B_ORDERS}/packaging/:id`;
  static B2B_ORDER_PACKAGING_WITHOUT_ID = `${TRoutes.B2B_ORDERS}/packaging/`;
  static B2B_ORDERS_DETAILS = `${this.B2B_ORDERS}/details/:id`;
  static B2B_ORDERS_DETAILS_WITHOUT_ID = `${this.B2B_ORDERS}/details/`;

  static NOT_FOUND = `/404`;
}

export const USER_STATUS = {
  NOT_ACTIVE: 0,
  ACTIVE: 1,
};

export const DEPARTURE_DATE_FILTER = {
  ALL_TIME: 'all',
  LAST_7: '1',
  LAST_30: '2',
};

export const EXTENDED_DATE_FILTERS = {
  ALL_TIME: 0,
  TODAY: 1,
  YESTERDAY: 2,
  LAST_7_DAYS: 3,
  LAST_30_DAYS: 4,
  RANGE: 5,
};

export const DELIVERY_VARIANTS = {
  FROM_WAREHOUSE_TO_DOOR: 0,
  FROM_DOOR_TO_DOOR: 1,
};

export const LIMIT_ITEM_PAGE = 20;

export const getInitialUser = () => ({
  name: '',
  company: '',
  country: {
    id: '',
    order: '',
    name: '',
    daysToDelivery: '',
    zoneId: '',
    nameEng: '',
    iso: '',
    phoneCode: '',
    phoneMin: '',
    phoneMax: '',
    customsInfo: '',
  },
  address: '',
  address2: '',
  address3: '',
  zipCode: '',
  city: '',
  state: '',
  phone: '',
  email: '',
  remark: '',
});

export const getInitialUnit = () => ({
  nameRU: '',
  nameEN: '',
  tradeMark: '',
  code: null,
  quantity: 1,
  price: 0,
  country: '',
  netWeight: 0,
  state: UNIT_STATES.UNKNOWN,
});

export const getInitialPackage = () => ({
  weightKg: '',
  lengthCm: '',
  widthCm: '',
  heightCm: '',
  units: [getInitialUnit()],
});

export const getInitialAddressBook = () => ({
  id: '',
  name: '',
  company: '',
  countryId: '',
  address: '',
  address2: '',
  address3: '',
  zipCode: '',
  city: '',
  state: '',
  phone: '',
  email: '',
  contactName: '',
  innKppCode: '',
  remark: '',
});

export const LIST_PROHIBITED_GOODS = [
  'Air Guns',
  'Animals, fish, birds (live)',
  'Bullion (of any precious metal)',
  'Cash (current legal tender)',
  'Cashlike negotiable instruments',
  'Dangerous goods, haz. or comb. mats',
  'Drugs(in pharmacy w/o prescription)',
  'Explosives and weapons',
  'Firearms (complete), ammunition',
  'Firearms, parts of',
  'Gambling device(incl. Playing card)',
  'Human remains, including ashes',
  'Hunting trophies,animal part/remain',
  'Illegal goods(counterfeit,narcotic)',
  'Imitation explos.devices,ammuniton',
  'Imitation(replica) firearms,weapons',
  'IVORY',
  'Loose (semi)precious stones',
  'PORNOGRAPHY',
  'PRECIOUS METALS & STONES',
];

export const sumByField = (arr, name, format = (val) => val) => {
  let sum = 0;
  if (!arr?.length) return format(sum);

  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];

    if (isNaN(item[name])) return t('app.incorrectData');

    sum += Number(item[name]);
  }

  return format(sum);
};

export const sumQuantityByField = (arr, nameField, format = (val) => val) => {
  let sum = 0;

  if (!arr?.length) return format(sum.toFixed(2));

  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];

    if (isNaN(item[nameField]) || isNaN(item.quantity))
      return t('app.incorrectData');

    sum += Number(item[nameField]) * Number(item.quantity);
  }

  return format(sum.toFixed(2));
};

/**
 * Общий объёмный вес = (высота * длина * ширина) / 5000
 * @param arr
 * @param format
 * @returns {string|*}
 */
export const sumTotalVolumetricWeight = (arr, format = (val) => val) => {
  let sum = 0;
  if (!arr) return;

  // No items in array - return zero
  if (!arr.length) return format(sum.toFixed(2));

  for (let i = 0; i < arr.length; i++) {
    const item = arr[i];

    if (
      isNaN(item['heightCm']) ||
      isNaN(item['widthCm']) ||
      isNaN(item['lengthCm'])
    )
      return t('app.incorrectData');

    const packageWeight =
      (+item['heightCm'] * +item['widthCm'] * +item['lengthCm']) / 5000;

    sum += packageWeight;
  }

  return format(sum.toFixed(2));
};

export function logout(history) {
  Object.values(LOCAL_STORAGE_KEYS).forEach((value) => {
    localStorage.removeItem(value);
  });

  client
    .clearStore()
    .then(() => client.resetStore())
    .catch((error) => console.log(error))
    .finally(() => history.push(TRoutes.AUTH_SIGN_IN));
}

const latinAlphabetAndNumbers = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T',
  'U',
  'V',
  'W',
  'X',
  'Y',
  'Z',
  'a',
  'b',
  'c',
  'd',
  'e',
  'f',
  'g',
  'h',
  'i',
  'j',
  'k',
  'l',
  'm',
  'n',
  'o',
  'p',
  'q',
  'r',
  's',
  't',
  'u',
  'v',
  'w',
  'x',
  'y',
  'z',
  '0',
  '1',
  '2',
  '3',
  '4',
  '5',
  '6',
  '7',
  '8',
  '9',
];
export const checkAllowedCharacters = (characters) => {
  return characters
    .split('')
    .every((char) => latinAlphabetAndNumbers.includes(char));
};

export const getStatusIdByName = (statuses, name) => {
  if (!Array.isArray(statuses)) {
    throw new Error('getStatusIdByName need an array value!');
  }
  return statuses.find((status) => status?.name === name)?.id;
};

export const CDEK_STATES = {
  ACCEPTED: t('app.accepted'),
  CREATED: t('app.created'),
  RECEIVED_AT_SENDER_WAREHOUSE: t('app.receivedAtSenderWarehouse'),
  READY_FOR_SHIPMENT_IN_SENDER_CITY: t('app.issuedForShipmentSenderCity'),
  RETURNED_TO_SENDER_CITY_WAREHOUSE: t('app.returnedToSenderWarehouse'),
  TAKEN_BY_TRANSPORTER_FROM_SENDER_CITY: t('app.handedOverToCarrierSenderCity'),
  SENT_TO_TRANSIT_CITY: t('app.sentToTransitCity'),
  ACCEPTED_IN_TRANSIT_CITY: t('app.metInTransitCity'),
  ACCEPTED_AT_TRANSIT_WAREHOUSE: t('app.receivedAtTransitWarehouse'),
  RETURNED_TO_TRANSIT_WAREHOUSE: t('app.returnedToTransitWarehouse'),
  READY_FOR_SHIPMENT_IN_TRANSIT_CITY: t('app.issuedForShipmentTransitCity'),
  TAKEN_BY_TRANSPORTER_FROM_TRANSIT_CITY: t(
    'app.handedOverToCarrierTransitCity',
  ),
  SENT_TO_RECIPIENT_CITY: t('app.sentToRecipientCity'),
  ARRIVED_AT_RECIPIENT_CITY: t('app.metInRecipientCity'),
  ACCEPTED_AT_RECIPIENT_CITY_WAREHOUSE: t('app.receivedAtDeliveryWarehouse'),
  ACCEPTED_AT_PICK_UP_POINT: t('app.receivedAtPickupWarehouse'),
  TAKEN_BY_COURIER: t('app.issuedForDelivery'),
  RETURNED_TO_RECIPIENT_CITY_WAREHOUSE: t('app.returnedToDeliveryWarehouse'),
  DELIVERED: t('app.delivered2'),
  NOT_DELIVERED: t('app.notDelivered'),
  INVALID: t('app.incorrectOrder'),
  SELFEXPORT: t('app.selfPickup'),
};

export const getWarehouseTafiffNameByCode = (code) => {
  switch (code) {
    case 136:
      return t('app.parcelWarehouseToWarehouse');
    case 138:
      return t('app.parcelDoorToWarehouse');
    case 62:
      return t('app.mainlineExpressWarehouseToWarehouse');
    case 63:
      return t('app.mainlineSuperExpressWarehouseToWarehouse');
    case 999:
      return t('app.selfPickup');
    default:
      return t('app.unknown');
  }
};

export const handleSelectDate = (e, filter, setFilter) => {
  setFilter((state) => {
    switch (e.target.value) {
      case EXTENDED_DATE_FILTERS.ALL_TIME: {
        return {
          ...state,
          page: 1,
          dateSelectValue: e.target.value,
          dateFrom: null,
          dateTo: moment(),
        };
      }
      case EXTENDED_DATE_FILTERS.TODAY: {
        return {
          ...state,
          page: 1,
          dateSelectValue: e.target.value,
          dateFrom: moment(),
          dateTo: moment(),
        };
      }
      case EXTENDED_DATE_FILTERS.YESTERDAY: {
        return {
          ...state,
          page: 1,
          dateSelectValue: e.target.value,
          dateFrom: moment().subtract(1, 'days'),
          dateTo: moment().subtract(1, 'days'),
        };
      }
      case EXTENDED_DATE_FILTERS.LAST_7_DAYS: {
        return {
          ...state,
          page: 1,
          dateSelectValue: e.target.value,
          dateFrom: moment().subtract(7, 'days'),
          dateTo: moment(),
        };
      }
      case EXTENDED_DATE_FILTERS.LAST_30_DAYS: {
        return {
          ...state,
          page: 1,
          dateSelectValue: e.target.value,
          dateFrom: moment().subtract(30, 'days'),
          dateTo: moment(),
        };
      }
      case EXTENDED_DATE_FILTERS.RANGE: {
        return {
          ...state,
          page: 1,
          dateSelectValue: e.target.value,
          dateFrom: filter.dateFrom ? filter.dateFrom : moment(),
          dateTo: filter.dateTo ? filter.dateTo : moment(),
        };
      }
      default:
        return;
    }
  });
};

export const getCountryISOByName = (name) => {
  if (
    name === 'Россия' ||
    name === 'Russia' ||
    name === 'Российская Федерация' ||
    name === 'Russian Federation'
  ) {
    return 'RU';
  }
  const dataCountries = client.readQuery({
    query: QUERY_DELIVERY_COUNTRIES,
  });
  return dataCountries?.deliveryCountries?.find((country) => {
    return country && (country.name === name || country.nameEng === name);
  })?.iso;
};

export const getCountryFlagUrlByISO = (iso) => {
  return `https://purecatamphetamine.github.io/country-flag-icons/3x2/${iso}.svg`;
};

export const getRequiredMelissaTitle = (country, city, zipCode, address) => {
  return (
    `${t('app.fillFields')}: ` +
    `${!country ? 'страна' : ''} ` +
    `${!city ? 'город' : ''} ` +
    `${!zipCode ? 'индекс' : ''} ` +
    `${!address ? 'адрес' : ''}`
  );
};

export const queryValidateAddressMelissa = (
  country,
  city,
  state,
  zipCode,
  address,
) => {
  return client.query({
    query: QUERY_VALIDATE_ADDRESS,
    variables: {
      input: {
        city: city,
        state: state,
        address: address,
        countryName: country.name,
        postalCode: zipCode,
        countryIso: country.iso,
      },
    },
    fetchPolicy: 'network-only',
  });
};

export const initialManagerValues = (warehouseManager) => {
  const initialValues = warehouseManager
    ? {
        warehouseId: warehouseManager.warehouse?.id,
        name: warehouseManager.name,
        email: warehouseManager.email,
      }
    : { warehouseId: 0, name: '', email: '', password: '' };
  return initialValues;
};

export const initialWarehouseValues = (warehouse) => {
  const initialValues = warehouse.id
    ? {
        managerName: warehouse.managerName,
        name: warehouse.code,
        address: warehouse.address,
        address2: warehouse.address2,
        company: warehouse.company,
        email: warehouse.email,
        phone: warehouse.phone,
        country: warehouse?.country,
        zipCode: warehouse.zipCode,
        city: warehouse.city,
        state: warehouse.state,
        countryId: warehouse?.country?.id,
        operations: warehouse?.availableOperation.map(
          (operation) => operation.operation,
        ),
      }
    : {
        managerName: '',
        name: '',
        address: '',
        address2: '',
        company: '',
        phone: '',
        countryId: '',
        email: '',
        zipCode: '',
        city: '',
        state: '',
        operations: [],
      };
  return initialValues;
};

export const isListOpenLocalStorage = (key) => {
  const localStorageValue = localStorage.getItem(key);
  return localStorageValue === 'true' || false;
};

export const getMovementOperationText = (history) => {
  if (history.operation) {
    const operation = t(MOVEMENT_OPERATIONS[history.operation]);
    if (operation) return operation;
  }

  return t('app.unknownOperation');
};

export const getDetails = (history) => {
  const isB2bShipment = !isNaN(Number(history?.comment?.slice(1)));
  switch (history.operation) {
    case WarehouseProductLogOperation.Inbound:
      return (
        <Link
          to={`${
            TRoutes.WAREHOUSE_SHIPMENT_DETAILS_WITHOUT_ID
          }${history.shipmentId?.toString()}`}
        >
          {history.comment}
        </Link>
      );
    case WarehouseProductLogOperation.Sending:
    case WarehouseProductLogOperation.OrderPicking:
    case WarehouseProductLogOperation.OrderCancel:
      const href = isB2bShipment
        ? `${
            TRoutes.B2B_ORDERS_DETAILS_WITHOUT_ID
          }${history.shipmentId?.toString()}`
        : `${
            TRoutes.PARCELS_FROM_WAREHOUSE_DETAILS_WITHOUT_ID
          }${history.shipmentId?.toString()}`;

      return <Link to={href}>{history.comment}</Link>;
    case WarehouseProductLogOperation.Return:
      return (
        <Link
          to={`${
            TRoutes.RETURN_DETAILS_WITHOUT_ID
          }${history.shipmentId?.toString()}`}
        >
          {history.comment}
        </Link>
      );
    case WarehouseProductLogOperation.UndeclaredInbound:
      return (
        <Link
          to={`${
            TRoutes.UNDECLARED_ARRIVAL_DETAILS_WITHOUT_ID
          }${history.shipmentId?.toString()}`}
        >
          {history.comment}
        </Link>
      );

    default:
      return history.comment;
  }
};

export const DASHBOARD_DEPARTURE_DATE_FILTER = {
  ALL_TIME: 0,
  TODAY: 1,
  YESTERDAY: 2,
  LAST_7: 3,
  LAST_30: 4,
  RANGE: 5,
};

export const handleDashboardSelectDate = (e, filter, setFilter) => {
  setFilter((state) => {
    switch (e.target.value) {
      case DASHBOARD_DEPARTURE_DATE_FILTER.TODAY: {
        return {
          ...state,
          dateSelectValue: e.target.value,
          dateFilter: {
            from: moment().format('YYYY-MM-DD'),
            to: moment().format('YYYY-MM-DD'),
          },
        };
      }
      case DASHBOARD_DEPARTURE_DATE_FILTER.YESTERDAY: {
        return {
          ...state,
          dateSelectValue: e.target.value,
          dateFilter: {
            from: moment().subtract(1, 'days').format('YYYY-MM-DD'),
            to: moment().subtract(1, 'days').format('YYYY-MM-DD'),
          },
        };
      }
      case DASHBOARD_DEPARTURE_DATE_FILTER.LAST_7: {
        return {
          ...state,
          dateSelectValue: e.target.value,
          dateFilter: {
            from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
            to: moment().format('YYYY-MM-DD'),
          },
        };
      }
      case DASHBOARD_DEPARTURE_DATE_FILTER.LAST_30: {
        return {
          ...state,
          dateSelectValue: e.target.value,
          dateFilter: {
            from: moment().subtract(30, 'days').format('YYYY-MM-DD'),
            to: moment().format('YYYY-MM-DD'),
          },
        };
      }
      case DASHBOARD_DEPARTURE_DATE_FILTER.RANGE: {
        return {
          ...state,
          dateSelectValue: e.target.value,
          dateFilter: {
            from: '',
            to: '',
          },
        };
      }
      default: {
        return state;
      }
    }
  });
};
