import { TRoutes } from '../utils/helpers';
import Users from '../viewes/Users/Users';
import ParcelTrack from '../viewes/ParcelTrack/ParcelTrack';
import PaymentStatistics from '../viewes/PaymentStatistics/PaymentStatistics';
import PaymentsHistory from '../viewes/PaymentsHistory/PaymentsHistory';
import SettingsCalculator from '../viewes/Settings/SettingsCalculator/SettingsCalculator';
import WarehouseShipmentDetails from '../viewes/Warehouse/WarehouseShipmentDetails/WarehouseShipmentDetails';
import WarehouseShipmentList from '../viewes/Warehouse/WarehouseShipmentList/WarehouseShipmentList';
import WarehouseList from '../viewes/Settings/WarehouseList/WarehousesList';
import Managers from '../viewes/Settings/Managers/Managers';
import Warehouse from '../viewes/Warehouse/Warehouse/Warehouse';
import MyproductDetails from '../viewes/Warehouse/Warehouse/MyProductDetails/MyproductDetails';
import ParecelFromWarehouseDetails from '../viewes/ParcelsFromWarehouse/ParecelFromWarehouseDetails/ParecelFromWarehouseDetails';
import Reports from '../viewes/Reports/Reports';
import Zones from '../viewes/Warehouse/AddressStorage/Zones';
import Cells from '../viewes/Warehouse/AddressStorage/Cells';
import Residues from '../viewes/Warehouse/Warehouse/Residues';
import SwapHistory from '../viewes/Warehouse/Warehouse/SwapHistory';
import ParcelsTabs from '../viewes/ParcelsFromWarehouse/ParcelsTabs/ParcelsTabs';
import ReturnShipmentsList from '../viewes/Returns/ReturnShipmentsList/ReturnShipmentsList';
import ReturnShipmentDetail from '../viewes/Returns/ReturnShipmentDetail/ReturnShipmentDetail';
import { UserRoles } from '../generated/graphql';
import UndeclaredArrivalList from '../viewes/Warehouse/UndeclaredArrivalList/UndeclaredArrivalList';
import UndeclaredArrivalDetails from '../viewes/Warehouse/UndeclaredArrivalDetails/UndeclaredArrivalDetails';
import Notifications from '../viewes/Notifications/Notifications';
import { SenderAccountsList } from '../viewes/Settings/SenderAccountsList/SenderAccountsList';
import SenderAccountsForm from '../viewes/Settings/SenderAccountsForm/SenderAccountsForm';
import B2bShipmentPackaging from '../viewes/B2bShipments/B2bShipmentPackaging/ B2bShipmentPackaging';
import B2bShipments from '../viewes/B2bShipments/B2bShipments';
import B2bShipmentsPoints from '../viewes/Settings/B2bShipmentsPoints/B2bShipmentsPoints';
import B2bShipmentServices from '../viewes/Settings/B2bShipmentServices/B2bShipmentServices';
import B2bShipmentDetails from '../viewes/B2bShipments/B2bShipmentDetails/B2bShipmentDetails';
import Dashboard from '../viewes/Dashboard/Dashboard';

export const routes = [
  {
    path: TRoutes.MAIN,
    component: Dashboard,
    roles: [UserRoles.SuperAdmin, UserRoles.Admin],
  },
  {
    path: TRoutes.MAIN,
    component: ParcelsTabs,
    roles: [UserRoles.SuperAdmin, UserRoles.Admin],
  },
  {
    path: TRoutes.CLIENTS,
    component: Users,
    roles: [UserRoles.SuperAdmin, UserRoles.Admin],
  },
  {
    path: TRoutes.PARCELS,
    component: ParcelsTabs,
    roles: [UserRoles.SuperAdmin, UserRoles.Admin],
  },
  {
    path: TRoutes.PARCELS_FROM_WAREHOUSE_DETAILS,
    component: ParecelFromWarehouseDetails,
    roles: [UserRoles.SuperAdmin, UserRoles.Admin],
  },
  {
    path: TRoutes.RETURNS,
    component: ReturnShipmentsList,
    roles: [UserRoles.SuperAdmin, UserRoles.Admin],
  },
  {
    path: TRoutes.RETURN_DETAILS,
    component: ReturnShipmentDetail,
    roles: [UserRoles.SuperAdmin, UserRoles.Admin],
  },
  {
    path: TRoutes.TRACK,
    component: ParcelTrack,
    roles: [UserRoles.SuperAdmin, UserRoles.Admin],
  },
  {
    path: TRoutes.REPORTS,
    component: Reports,
    roles: [UserRoles.SuperAdmin, UserRoles.Admin],
  },
  {
    path: TRoutes.PARCEL_TRACK_WITH_CARRIER,
    component: ParcelTrack,
    roles: [UserRoles.SuperAdmin, UserRoles.Admin],
  },
  {
    path: TRoutes.PAYMENT_STATISTICS,
    component: PaymentStatistics,
    roles: [UserRoles.SuperAdmin, UserRoles.Admin],
  },
  {
    path: TRoutes.PAYMENT_STATISTICS_PER_USER,
    component: PaymentsHistory,
    roles: [UserRoles.SuperAdmin, UserRoles.Admin],
  },
  {
    path: TRoutes.WAREHOUSE_SHIPMENT_DETAILS,
    component: WarehouseShipmentDetails,
    roles: [UserRoles.SuperAdmin, UserRoles.Admin],
  },
  {
    path: TRoutes.WAREHOUSE_SHIPMENT_LIST,
    component: WarehouseShipmentList,
    roles: [UserRoles.SuperAdmin, UserRoles.Admin],
  },
  {
    path: TRoutes.WAREHOUSE_SHIPMENT_FORWARDING_LIST,
    component: WarehouseShipmentList,
    roles: [UserRoles.SuperAdmin, UserRoles.Admin],
  },
  {
    path: TRoutes.WAREHOUSES_LIST,
    component: WarehouseList,
    roles: [UserRoles.SuperAdmin],
  },
  {
    path: TRoutes.WAREHOUSE,
    component: Warehouse,
    roles: [UserRoles.SuperAdmin, UserRoles.Admin],
  },
  {
    path: TRoutes.WAREHOUSE_RESIDUES,
    component: Residues,
    roles: [UserRoles.Admin, UserRoles.SuperAdmin],
  },
  {
    path: TRoutes.WAREHOUSE_SWAP_HISTORY,
    component: SwapHistory,
    roles: [UserRoles.Admin, UserRoles.SuperAdmin],
  },
  {
    path: TRoutes.WAREHOUSE_MY_PRODUCT_DETAILS,
    component: MyproductDetails,
    roles: [UserRoles.Admin, UserRoles.SuperAdmin],
  },
  {
    path: TRoutes.WAREHOUSES_MANAGERS,
    component: Managers,
    roles: [UserRoles.SuperAdmin],
  },
  {
    path: TRoutes.SETTINGS_CALCULATOR,
    component: SettingsCalculator,
    roles: [UserRoles.SuperAdmin],
  },
  {
    path: TRoutes.WAREHOUSE_ZONES,
    component: Zones,
    roles: [UserRoles.Admin],
  },
  {
    path: TRoutes.WAREHOUSE_CELLS,
    component: Cells,
    roles: [UserRoles.Admin],
  },
  {
    path: TRoutes.UNDECLARED_ARRIVAL_LIST,
    component: UndeclaredArrivalList,
    roles: [UserRoles.Admin, UserRoles.SuperAdmin],
  },
  {
    path: TRoutes.UNDECLARED_ARRIVAL_DETAILS,
    component: UndeclaredArrivalDetails,
    roles: [UserRoles.Admin, UserRoles.SuperAdmin],
  },
  {
    path: TRoutes.NOTIFICATIONS,
    component: Notifications,
    roles: [UserRoles.SuperAdmin],
  },
  {
    path: TRoutes.SETTINGS_SENDER_ACCOUNTS,
    component: SenderAccountsList,
    roles: [UserRoles.SuperAdmin],
  },
  {
    path: TRoutes.SETTINGS_SENDER_ACCOUNTS_CREATE,
    component: SenderAccountsForm,
    roles: [UserRoles.SuperAdmin],
  },
  {
    path: TRoutes.SETTINGS_SENDER_ACCOUNTS_UPDATE,
    component: SenderAccountsForm,
    roles: [UserRoles.SuperAdmin],
  },
  {
    path: TRoutes.B2B_ORDERS,
    component: B2bShipments,
    roles: [UserRoles.Admin, UserRoles.SuperAdmin],
  },
  {
    path: TRoutes.B2B_ORDERS_DETAILS,
    component: B2bShipmentDetails,
    roles: [UserRoles.Admin, UserRoles.SuperAdmin],
  },
  {
    path: TRoutes.B2B_ORDER_PACKAGING,
    component: B2bShipmentPackaging,
    roles: [UserRoles.Admin, UserRoles.SuperAdmin],
  },
  {
    path: TRoutes.SETTINGS_B2B_SHIPMENTS_POINTS,
    component: B2bShipmentsPoints,
    roles: [UserRoles.SuperAdmin],
  },
  {
    path: TRoutes.SETTINGS_B2B_SHIPMENTS_SERVICES,
    component: B2bShipmentServices,
    roles: [UserRoles.SuperAdmin],
  },
];
