import React, { useEffect, useState } from 'react';
import {
  ListItem,
  Tooltip,
  Typography,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Box,
  TableBody,
  Button,
  useMediaQuery,
  useTheme,
  Divider,
} from '@material-ui/core';
import useStyles from './styles';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';

import { useParams, useHistory, Link } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  ShipmentWarehouseStatus,
  useCancelWarehouseShipmentOrderMutation,
  useGetWarehouseShipmentOrderAdditionalOptionsQuery,
  useGetWarehouseShipmentOrderByIdQuery,
  Warehouse,
  Warehouse_Shipment_Order_Type,
  WarehouseShipmentOrder,
} from '../../../generated/graphql';
import { BoxCentered } from '../../../components/BoxCentered/BoxCentered';
import {
  ConvertWarehouseStatusIdToColorForAdmin,
  ShowLoadingText,
} from '../../../utils/helperComponents';
import ParcelDetailsContact from '../../../components/ParcelDetails/ParcelDetailsContact/ParcelDetailsContact';
import { sumQuantityByField, TRoutes } from '../../../utils/helpers';
import { COLORS, LOCAL_STORAGE_KEYS } from '../../../utils/constants';
import ModalBox from '../../../components/ModalBox/ModalBox';

import SendWarehouseShipmentOrder from '../../../components/SendWarehouseShipmentOrder/SendWarehouseShipmentOrder';
import ParcelDetailsWarehouse from '../../../components/ParcelDetails/ParcelDetailsWarehouse/ParcelDetailsWarehouse';
import { useTranslation } from 'react-i18next';
import TrackTrackNumberComponent from '../../../components/TrackTrackNumberComponent/TrackTrackNumberComponent';
import PhotoComponent from '../../../components/PhotoComponent/PhotoComponent';

export interface IWrapTypes {
  cost: number;
  name: string;
}

const ShipmentFromWarehouseDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMediaQueryXs = useMediaQuery(theme.breakpoints.down('xs'));
  const { id } = useParams<{ id: string }>();

  const { t } = useTranslation();

  const CURRENT_APP_CURRENCY = localStorage.getItem(
    LOCAL_STORAGE_KEYS.CURRENT_APP_CURRENCY,
  );

  const [isOpenConfirmCancel, setOpenConfirmCancel] = useState(false);
  const [isOpenSendParcel, setOpenSendParcel] = useState(false);
  const [warehouseShipmentOrder, setWarehouseShipmentOrder] =
    useState<WarehouseShipmentOrder | null>(null);

  const { data, loading, error, refetch } =
    useGetWarehouseShipmentOrderByIdQuery({
      variables: {
        id,
      },
    });

  const { data: addOptionsData, loading: addOptionsLoading } =
    useGetWarehouseShipmentOrderAdditionalOptionsQuery({
      fetchPolicy: 'network-only',
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
    });

  const [cancelWarehouseShipmentOrderMutation] =
    useCancelWarehouseShipmentOrderMutation();

  useEffect(() => {
    if (!data?.getWarehouseShipmentOrderById) return;
    data?.getWarehouseShipmentOrderById &&
      setWarehouseShipmentOrder(
        data?.getWarehouseShipmentOrderById as WarehouseShipmentOrder,
      );
  }, [data]);

  const getTotalQuantity = (arr: any) => {
    let count = 0;
    arr?.forEach((product: any) => (count += product?.quantity));
    return count;
  };

  error?.message && enqueueSnackbar(error.message, { variant: 'error' });

  const handlerCancelWarehouseShipmentOrder = (id: string) => {
    cancelWarehouseShipmentOrderMutation({ variables: { id } })
      .then(() => {
        enqueueSnackbar(t('app.orderCanceledT'), { variant: 'success' });
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      })
      .finally(() => {
        setOpenConfirmCancel(false);
        refetch();
      });
  };

  const getWrapLabel = (wrap?: string) => {
    const wrapper =
      addOptionsData?.getWarehouseShipmentOrderAdditionalOptions?.wrappers?.find(
        (wrapper: IWrapTypes) => wrapper?.name === wrap,
      );

    return `${wrapper?.name} - ${wrapper?.cost} ${CURRENT_APP_CURRENCY}`;
  };

  const isWithLabel =
    warehouseShipmentOrder?.type === Warehouse_Shipment_Order_Type.WithBuyLabel;

  return (
    <>
      {!(loading || addOptionsLoading) ? (
        <>
          <Box mb={4}></Box>
          <Typography variant='h2' align='center' className={classes.mainTitle}>
            {t('app.viewOrder')}
          </Typography>
          <ListItem>
            <Typography variant='h3'>
              {t('app.orderNumber')}:{' '}
              {warehouseShipmentOrder?.barcodeId?.substring(
                0,
                warehouseShipmentOrder?.barcodeId.length - 4,
              )}
            </Typography>
          </ListItem>
          <ListItem className={classes.itemInfo}>
            {t('app.creationDate')}: {/* @ts-ignore */}
            {new Date(warehouseShipmentOrder?.createdAt).toLocaleDateString()}
          </ListItem>

          <ListItem className={classes.itemInfo}>
            {t('app.orderClient')}:{'  '}
            <Link
              to={TRoutes.PAYMENT_STATISTICS_PER_USER.replace(
                ':userId',
                //@ts-ignore
                warehouseShipmentOrder?.clientUser?.id.toString(),
              )}
            >
              {warehouseShipmentOrder?.clientUser?.name}
            </Link>
          </ListItem>
          <ListItem className={classes.itemInfo}>
            {t('app.option')}:{' '}
            {warehouseShipmentOrder?.carrierCode
              ? warehouseShipmentOrder?.carrierCode.toUpperCase() + ' '
              : ''}
            {isWithLabel ? warehouseShipmentOrder?.carrierService : ''}
          </ListItem>

          {warehouseShipmentOrder?.trackNumber &&
            warehouseShipmentOrder?.carrierService && (
              <ListItem className={classes.itemInfo}>
                {`${t('app.trackingNumber')}: `}
                <TrackTrackNumberComponent
                  carrierCode={String(warehouseShipmentOrder?.carrierCode)}
                  trackNumber={warehouseShipmentOrder?.trackNumber}
                />
              </ListItem>
            )}
          <ListItem className={classes.itemInfo}>
            {t('app.deliveryStatus')}:{' '}
            <ConvertWarehouseStatusIdToColorForAdmin
              status={warehouseShipmentOrder?.warehouseStatus as string}
            />
          </ListItem>
          {warehouseShipmentOrder?.paymentStatus?.explain && (
            <ListItem className={classes.itemInfo}>
              {t('app.paymentStatus')}:{' '}
              {warehouseShipmentOrder?.paymentStatus?.explain}
            </ListItem>
          )}
          <Grid container spacing={0} alignItems='stretch'>
            <Grid item sm={6} xs={12}>
              <ParcelDetailsWarehouse
                type='sender'
                contact={warehouseShipmentOrder?.sender as Warehouse}
              />
            </Grid>

            {isWithLabel ? (
              <Grid item sm={6} xs={12}>
                <ParcelDetailsContact
                  type='receiver'
                  //@ts-ignore
                  contact={warehouseShipmentOrder?.receiver}
                />
              </Grid>
            ) : null}
          </Grid>
          <ListItem>
            <Typography variant='h3'>{t('app.orderInformation')}</Typography>
          </ListItem>
          <ListItem className={classes.itemInfo}>
            {t('app.numberOfItems')}:{' '}
            {getTotalQuantity(warehouseShipmentOrder?.products)}
          </ListItem>
          <ListItem className={classes.itemInfo}>
            {t('app.itemCost')}:{' '}
            {sumQuantityByField(
              warehouseShipmentOrder?.products as any[],
              'price',
              (val) => val,
            )}{' '}
            {warehouseShipmentOrder?.currency}
          </ListItem>
          {warehouseShipmentOrder?.trackNumber && isWithLabel && (
            <ListItem className={classes.itemInfo}>
              {`${t('app.dimensionsAndWeight')}: ${
                warehouseShipmentOrder?.dimensions?.length
              } x ${warehouseShipmentOrder?.dimensions?.width} x ${
                warehouseShipmentOrder?.dimensions?.height
              } ${t('app.cm')}, ${
                warehouseShipmentOrder?.dimensions?.weight
              } ${t('app.kg')}`}
            </ListItem>
          )}
          {warehouseShipmentOrder?.declaredDimensions && isWithLabel && (
            <ListItem className={classes.itemInfo}>
              {`${t('app.dimensionsWeightClaimed')}: ${
                warehouseShipmentOrder?.declaredDimensions?.length
              } x ${warehouseShipmentOrder?.declaredDimensions?.width} x ${
                warehouseShipmentOrder?.declaredDimensions?.height
              } ${t('app.cm')}, ${
                warehouseShipmentOrder?.declaredDimensions?.weight
              } ${t('app.kg')}`}
            </ListItem>
          )}
          {warehouseShipmentOrder?.vatKey !== 'EMPTY' && (
            <ListItem className={classes.itemInfo}>
              {t('app.taxIdNumber')}: {warehouseShipmentOrder?.vatKey}{' '}
              {warehouseShipmentOrder?.vatValue}
            </ListItem>
          )}
          {isWithLabel ? (
            <>
              {warehouseShipmentOrder?.additionalInsurance ? (
                <>
                  <ListItem className={classes.itemInfo}>
                    {`${t('app.additionalInsurance')}: ${t('app.yes')}`}
                  </ListItem>
                  <ListItem className={classes.itemInfo}>
                    {`${t('app.insuranceCost')}: ${
                      warehouseShipmentOrder?.insuranceAmount
                    } ${CURRENT_APP_CURRENCY}`}
                  </ListItem>
                </>
              ) : null}
              {warehouseShipmentOrder?.signature ? (
                <ListItem className={classes.itemInfo}>
                  {`${t('app.recipientSignature')}: ${t('app.yes')}`}
                </ListItem>
              ) : null}
            </>
          ) : null}
          {warehouseShipmentOrder?.comment && (
            <ListItem className={classes.itemInfo}>
              {t('app.warehouseComment')}: {warehouseShipmentOrder?.comment}
            </ListItem>
          )}
          {warehouseShipmentOrder?.wrap && (
            <ListItem className={classes.itemInfo}>
              {`${t('app.packaging')}: ${getWrapLabel(
                warehouseShipmentOrder?.wrap,
              )}`}
            </ListItem>
          )}
          {warehouseShipmentOrder?.message && (
            <ListItem
              className={classes.itemInfo}
              style={{ color: COLORS.RED }}
            >
              {t('app.giftMessage')}: {warehouseShipmentOrder?.message}
            </ListItem>
          )}
          {!!warehouseShipmentOrder?.giftWrap && (
            <ListItem
              className={classes.itemInfo}
              style={{ color: COLORS.RED }}
            >
              {t('app.giftPackaging')}: {t('app.yes')}
            </ListItem>
          )}
          {warehouseShipmentOrder?.actualAmount && (
            <ListItem className={classes.itemInfo}>
              {`${t('app.shipmentCost')}: ${
                warehouseShipmentOrder?.actualAmount
              } ${
                warehouseShipmentOrder?.orderCostCurrency ||
                CURRENT_APP_CURRENCY
              }`}
            </ListItem>
          )}

          <Box pb={1} pt={1} pl={2}>
            <Typography variant='h6'>{t('app.listOfItems')}</Typography>
          </Box>

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align='center'>№</TableCell>
                  <TableCell align='center'>{t('app.photo')}</TableCell>
                  <TableCell align='center'>ID</TableCell>
                  <TableCell align='center'>SKU</TableCell>
                  <TableCell align='center'>{t('app.product')}</TableCell>
                  <TableCell align='center'>{t('app.quantity')}</TableCell>
                  <TableCell align='center'>
                    {t('app.weight')} {t('app.kg')}
                  </TableCell>
                  <TableCell align='center'>
                    {t('app.costT')} {warehouseShipmentOrder?.currency}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {warehouseShipmentOrder?.products?.map((product, index) => {
                  return (
                    <TableRow>
                      <TableCell align='center'>{index + 1}</TableCell>
                      <TableCell align='center'>
                        <BoxCentered>
                          <PhotoComponent
                            photoName={product?.relatedProduct?.photo}
                            width={'38px'}
                            mr='12px'
                          />
                        </BoxCentered>
                      </TableCell>
                      <TableCell align='center'>
                        <Link
                          to={TRoutes.WAREHOUSE_MY_PRODUCT_DETAILS.replace(
                            ':id',
                            product?.relatedProduct?.id?.toString() as string,
                          )}
                        >
                          {product?.relatedProduct?.barcodeId?.substring(
                            0,
                            product?.relatedProduct?.barcodeId?.length - 4,
                          )}
                        </Link>
                      </TableCell>
                      <TableCell align='center'>
                        {product?.relatedProduct?.sku}
                      </TableCell>
                      <TableCell align='center'>
                        {product?.relatedProduct?.name}
                      </TableCell>
                      <TableCell align='center'>{product?.quantity}</TableCell>
                      <TableCell align='center'>
                        {product?.relatedProduct?.netWeight}
                      </TableCell>
                      <TableCell align='center'>{product?.price}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      ) : (
        <BoxCentered>
          <ShowLoadingText name={t('app.shipment')} />
        </BoxCentered>
      )}
      <div className={classes.wrapButtons}>
        <Tooltip title={t('app.goToAllOrdersList')}>
          <Button
            fullWidth={isMediaQueryXs}
            variant='contained'
            startIcon={<ArrowBackIcon />}
            onClick={() => history.push(TRoutes.PARCELS)}
          >
            {t('app.back')}
          </Button>
        </Tooltip>
        <Divider orientation={isMediaQueryXs ? 'horizontal' : 'vertical'} />
        <Tooltip title={t('app.downloadLabelInXLSX')}>
          <Button
            fullWidth={isMediaQueryXs}
            variant='contained'
            disabled={
              warehouseShipmentOrder?.warehouseStatus !==
              ShipmentWarehouseStatus.Shipped
            }
            startIcon={<SaveIcon />}
            onClick={() => {
              warehouseShipmentOrder?.pathNameFile
                ? window.open(
                    `${process.env.REACT_APP_API_DOMAIN}/api/getReceipt/${warehouseShipmentOrder.pathNameFile}`,
                  )
                : enqueueSnackbar(t('app.serverTokenError'), {
                    variant: 'error',
                  });
            }}
          >
            {t('app.label')}
          </Button>
        </Tooltip>
        <Divider orientation={isMediaQueryXs ? 'horizontal' : 'vertical'} />
        <Tooltip title={t('app.cancelOrderMessage')}>
          <Button
            fullWidth={isMediaQueryXs}
            variant='contained'
            startIcon={<CancelIcon style={{ color: COLORS.RED }} />}
            disabled={
              warehouseShipmentOrder?.warehouseStatus ===
              ShipmentWarehouseStatus.Unshipped
            }
            onClick={() => {
              setOpenConfirmCancel(true);
            }}
          >
            {t('app.cancel')}
          </Button>
        </Tooltip>
        <Divider orientation={isMediaQueryXs ? 'horizontal' : 'vertical'} />
        <Tooltip title={t('app.sendThisOrder')}>
          <Button
            fullWidth={isMediaQueryXs}
            variant='contained'
            startIcon={<LocalShippingIcon style={{ color: COLORS.BLUE }} />}
            onClick={() => setOpenSendParcel(true)}
            disabled={
              warehouseShipmentOrder?.warehouseStatus !==
              ShipmentWarehouseStatus.Picked
            }
          >
            {t('app.send')}
          </Button>
        </Tooltip>
        <Divider orientation={isMediaQueryXs ? 'horizontal' : 'vertical'} />
      </div>
      <ModalBox isOpen={isOpenConfirmCancel} setOpen={setOpenConfirmCancel}>
        <Typography variant='h6'>{t('app.confirmCancelOrder')}</Typography>
        <div className={classes.boxModalButtons}>
          <Button
            variant='contained'
            color='secondary'
            onClick={() => setOpenConfirmCancel(false)}
          >
            {t('app.close')}
          </Button>
          <Button
            variant='contained'
            onClick={() =>
              handlerCancelWarehouseShipmentOrder(
                warehouseShipmentOrder?.id as string,
              )
            }
          >
            {t('app.cancelTheOrder')}
          </Button>
        </div>
      </ModalBox>
      <SendWarehouseShipmentOrder
        isOpenSendParcel={isOpenSendParcel}
        setOpenSendParcel={setOpenSendParcel}
        id={warehouseShipmentOrder?.id as string}
        isWithLabel={isWithLabel}
      />
    </>
  );
};

export default ShipmentFromWarehouseDetails;
