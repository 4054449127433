import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import useStyles from './styles';
import { IDashboardFilters } from '../../utils/types';

import {
  getFirstRowOption,
  getSecondRowOption,
  getThirdRowOption,
} from './options';
import {
  DashboardAdminStatistic,
  ProductQtyPerDay,
  UserRoles,
  useGetAdminDashboardStatisticLazyQuery,
  useGetCurrentUserQuery,
} from '../../generated/graphql';
import { useSnackbar } from 'notistack';
import RequestHandler from '../../components/RequestHandler/RequestHandler';

import moment from 'moment';
import BoxComponent from '../../components/DashboardComponents/BoxComponent/BoxComponent';
import GraphicComponent from '../../components/DashboardComponents/ChartComponents/GraphicComponent/GraphicComponent';
import FilterSelectDashboardDepartureDate from '../../components/FilterComponents/DashboardFilters/FilterSelectDashboardDepartureDate/FilterSelectDashboardDepartureDate';
import FilterSelectDashboardWarehouse from '../../components/FilterComponents/DashboardFilters/FilterSelectDasboardWarehouse/FilterSelectDashboardWarehouse';

const Dashboard = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { data: currentUserData } = useGetCurrentUserQuery();

  const isSuperAdmin =
    currentUserData?.currentUser?.role === UserRoles.SuperAdmin;

  const [filter, setFilter] = useState<IDashboardFilters>({
    dateFilter: {
      from: moment().subtract(7, 'days').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
    },
    dateSelectValue: 3,
    warehouseId: 0,
  });

  const [getDashboardStatisticLazy, { data, loading }] =
    useGetAdminDashboardStatisticLazyQuery({
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
      fetchPolicy: 'network-only',
    });

  useEffect(() => {
    getDashboardStatisticLazy({
      variables: {
        dateFilter: filter.dateFilter,
        ...(isSuperAdmin && { warehouseId: filter.warehouseId }),
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter.dateFilter.from, filter.dateFilter.to, filter.warehouseId]);

  return (
    <>
      <div className={classes.filters}>
        <FilterSelectDashboardDepartureDate
          value={filter}
          setFilter={setFilter}
        />
        {isSuperAdmin ? (
          <FilterSelectDashboardWarehouse
            value={filter}
            setFilter={setFilter}
          />
        ) : null}
      </div>

      <RequestHandler loading={loading}>
        <Box className={classes.pageWrapper}>
          <Box className={classes.boxesWrapper}>
            {getFirstRowOption(
              data?.getAdminDashboardStatistic as DashboardAdminStatistic,
            ).map(({ title, count, isCurrency, iconUrl }) => (
              <BoxComponent
                key={title}
                title={title}
                count={count}
                isCurrency={isCurrency}
                iconUrl={iconUrl}
              />
            ))}
            <BoxComponent isTransparent />
          </Box>
          <Box className={classes.graphWrapper}>
            <GraphicComponent
              ordersPerDayList={
                data?.getAdminDashboardStatistic
                  ?.productQtySentPerDay as ProductQtyPerDay[]
              }
            />
            {/* <DoghuntComponent
              orderStatuses={
                data?.getDashboardStatistic
                  ?.orderStatuses as OrderStatusesStatictic[]
              }
            /> */}
          </Box>
          <Box className={classes.boxesWrapper}>
            {getSecondRowOption(
              data?.getAdminDashboardStatistic as DashboardAdminStatistic,
            ).map(({ title, count, Icon, isCurrency }) => (
              <BoxComponent
                key={title}
                title={title}
                count={count}
                Icon={Icon}
                isCurrency={isCurrency}
              />
            ))}
            <BoxComponent isTransparent />
          </Box>
          <Box className={classes.boxesWrapper}>
            {getThirdRowOption(
              data?.getAdminDashboardStatistic as DashboardAdminStatistic,
            ).map(({ title, count, Icon, isCurrency, iconUrl }) => (
              <BoxComponent
                key={title}
                title={title}
                count={count}
                Icon={Icon}
                isCurrency={isCurrency}
                iconUrl={iconUrl}
              />
            ))}
            <BoxComponent isTransparent />
          </Box>
        </Box>
      </RequestHandler>
    </>
  );
};

export default Dashboard;
