import React from 'react';
import noPhotoIconUrl from '../../assets/img/icon_no_photo.png';
import useStyles from './styles';
import { BoxCentered } from '../BoxCentered/BoxCentered';

const PhotoComponent = ({
  photoName,
  width,
  mr = '0px',
}: {
  photoName?: string | null;
  width: string;
  mr?: string;
}) => {
  const classes = useStyles();
  return (
    <BoxCentered style={{ width, height: width, marginRight: mr }}>
      {!!photoName ? (
        <img
          style={{ width, height: width }}
          className={classes.imageComponent}
          src={`${process.env.REACT_APP_API_DOMAIN}/api/getProductFile/${photoName}`}
          alt={`product-${photoName}`}
        />
      ) : (
        <img
          className={classes.imageComponent}
          style={{ width, height: width }}
          src={noPhotoIconUrl}
          alt={'no_photo'}
        />
      )}
    </BoxCentered>
  );
};

export default PhotoComponent;
