import { Box, SvgIconTypeMap, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { Maybe } from '../../../generated/graphql';

const BoxComponent = ({
  title,
  count,
  Icon,
  isCurrency,
  iconUrl,
  isTransparent = false,
}: {
  title?: string;
  count?: Maybe<number>;
  Icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  iconUrl?: string;
  isCurrency?: boolean;
  isTransparent?: boolean;
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <Box
      className={classes.boxWrapper}
      style={{ ...(isTransparent && { backgroundColor: 'transparent' }) }}
    >
      <Typography className={classes.boxTitle}>{t(title as string)}</Typography>
      <Box className={classes.boxCountWrapper}>
        <Typography className={classes.boxCount}>{count}</Typography>
        {iconUrl ? (
          <img
            src={iconUrl}
            alt={t(title as string)}
            className={classes.icon}
          />
        ) : (
          Icon && <Icon className={classes.icon} style={{ height: '72px' }} />
        )}
      </Box>
    </Box>
  );
};

export default BoxComponent;
