import PackageIcon from '../../assets/img/icon_package_black_big.png';
import ScanIcon from '../../assets/img/icon_scan_package_black_big.png';
import DollyIcon from '../../assets/img/icon_dolly_black_big.png';
import WarehouseIcon from '../../assets/img/icon_warehouse_black_big.png';
import FastShippingIcon from '../../assets/img/icon_fast_shipping_black_big.png';
import StraightenIcon from '@material-ui/icons/Straighten';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import CategoryIcon from '@material-ui/icons/Category';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import { DashboardAdminStatistic } from '../../generated/graphql';

export const getFirstRowOption = (statistic?: DashboardAdminStatistic) => [
  {
    title: 'app.openOrders',
    count: statistic?.openOrdersCount ?? 0,
    iconUrl: PackageIcon,
    isCurrency: false,
  },
  {
    title: 'app.productsToPickCount',
    count: statistic?.productsToPickCount ?? 0,
    iconUrl: ScanIcon,
    isCurrency: false,
  },
  {
    title: 'app.productsToPlaceCount',
    count: statistic?.productsToPlaceCount ?? 0,
    iconUrl: DollyIcon,
    isCurrency: false,
  },
];

export const getSecondRowOption = (statistic?: DashboardAdminStatistic) => [
  {
    title: 'app.inStock',
    count: statistic?.totalProductQuantity ?? 0,
    Icon: ShoppingBasketIcon,
    isCurrency: false,
  },
  {
    title: 'app.skusInStock',
    count: statistic?.totalUniqueSKUCount ?? 0,
    Icon: CategoryIcon,
    isCurrency: false,
  },
  {
    title: 'app.productsVolume',
    count: statistic?.totalStorageProductsVolume
      ? Number(statistic?.totalStorageProductsVolume.toFixed(2))
      : 0,
    Icon: StraightenIcon,
    isCurrency: true,
  },
];

export const getThirdRowOption = (statistic?: DashboardAdminStatistic) => [
  {
    title: 'app.graphShipments',
    count: statistic?.totalShippedProductQuantity ?? 0,
    iconUrl: FastShippingIcon,
    isCurrency: false,
  },
  {
    title: 'app.receivedProducts',
    count: statistic?.totalAcceptedProductQuantity ?? 0,
    iconUrl: WarehouseIcon,
    isCurrency: false,
  },
  {
    title: 'app.expectedProducts',
    count: statistic?.totalInTransitProductQuantity ?? 0,
    Icon: LocalShippingIcon,
    isCurrency: false,
  },
];
