import { Box, FormControl, MenuItem, Select } from '@material-ui/core';
import React, { useEffect, useState } from 'react';

import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import {
  Warehouse,
  useGetWarehousesListQuery,
} from '../../../../generated/graphql';
import { IDashboardFilters } from '../../../../utils/types';
import { LOCAL_STORAGE_KEYS } from '../../../../utils/constants';
import useStyles from '../styles';
import iconWarehouse from '../../../../assets/img/icon_warehouse.png';

const FilterSelectDashboardWarehouse: React.FC<{
  value: IDashboardFilters;
  setFilter: React.Dispatch<React.SetStateAction<IDashboardFilters>>;
}> = ({ value, setFilter }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [warehouseList, setWarehouseList] = useState<Warehouse[] | null>(null);

  const { data: warehousesData, loading } = useGetWarehousesListQuery({
    onError: (error) => {
      if (error) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    },
  });

  useEffect(() => {
    if (warehousesData) {
      setWarehouseList(warehousesData?.getWarehousesList?.rows as Warehouse[]);
    }
  }, [warehousesData]);

  const onChangeHandler = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
  ) => {
    localStorage.setItem(
      LOCAL_STORAGE_KEYS.DASHBOARD_WAREHOUSE_ID_ORDER,
      e.target.value as string,
    );
    setFilter((state) => ({
      ...state,
      warehouseId: Number(e.target.value),
    }));
  };

  return (
    <Box className={classes.wrapper}>
      <img
        src={iconWarehouse}
        alt='icon_warehouse'
        className={classes.icon}
        style={{ marginBottom: '5px' }}
      />
      <FormControl className={classes.filtersItemSelect}>
        <Select
          displayEmpty
          disableUnderline
          disabled={loading}
          value={value.warehouseId}
          onChange={onChangeHandler}
        >
          <MenuItem value={0}>{t('app.allWarehouses')}</MenuItem>
          {warehouseList?.map((item: any) => (
            <MenuItem key={item.code} value={item.id}>
              {item.code}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default FilterSelectDashboardWarehouse;
