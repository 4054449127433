import React from 'react';
import {
  Box,
  MenuItem,
  Select,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { TRoutes } from '../../../utils/helpers';
import {
  ActionsItem,
  ConvertWarehouseStatusIdToColorForAdmin,
} from '../../../utils/helperComponents';
import { useTranslation } from 'react-i18next';
import useStyles from './styles';
import TrackTrackNumberComponent from '../../TrackTrackNumberComponent/TrackTrackNumberComponent';
import { AdditionalService } from '../AdditionalService/AdditionalService';
import { IShipmentFromWarehouseTableRowComponent } from './types';
import { getActionOptions } from './options';
import {
  LOCAL_STORAGE_KEYS,
  PAYMENT_ORDER_STATUS_BY_ID,
} from '../../../utils/constants';
import { ShipmentWarehouseStatus } from '../../../generated/graphql';
import PhotoComponent from '../../PhotoComponent/PhotoComponent';

const ShipmentFromWarehouseTableRowComponent = ({
  order,
  setOrderIdToCancel,
  setIsOpenConfirmCancel,
  sendWarehouseSipmentOrderHandler,
  isWithLabel,
  wrappers,
}: IShipmentFromWarehouseTableRowComponent) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const CURRENT_APP_CURRENCY = localStorage.getItem(
    LOCAL_STORAGE_KEYS.CURRENT_APP_CURRENCY,
  );

  const openModalForCancelHandler = () => {
    setOrderIdToCancel(Number(order?.id));
    setIsOpenConfirmCancel(true);
  };

  const getProductBarcodeTitle = (barcode: string) => barcode?.split('.')[0];

  const formattedDate = new Date(
    order?.createdAt as string,
  ).toLocaleDateString();

  const orderIdTitle = order?.barcodeId?.split('.')[0];

  const options = getActionOptions(
    order,
    isWithLabel,
    openModalForCancelHandler,
    sendWarehouseSipmentOrderHandler,
  );

  return (
    <TableRow>
      <TableCell align='center'>
        <Link
          to={`${
            TRoutes.PARCELS_FROM_WAREHOUSE_DETAILS_WITHOUT_ID
          }${order?.id?.toString()}`}
        >
          {orderIdTitle}
        </Link>
        <br />
        {formattedDate}
      </TableCell>
      <TableCell align='center'>
        <Link
          to={`${
            TRoutes.PAYMENT_STATISTICS_PER_USER_WITHOUT_ID
          }${order?.clientUser?.id.toString()}`}
        >
          {order?.clientUser?.name}
        </Link>
      </TableCell>
      <TableCell>
        {order?.products?.map((product) => (
          <div key={product?.relatedProduct?.barcodeId}>
            <Box
              display={'flex'}
              justifyContent={'start'}
              alignItems={'center'}
            >
              <PhotoComponent
                photoName={product?.relatedProduct?.photo}
                width={'46px'}
                mr='12px'
              />
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'start'}
              >
                <Typography
                  className={classes.productTitle}
                  align='left'
                >{`${product?.quantity} x ${product?.relatedProduct?.name}`}</Typography>

                <Link
                  to={`${
                    TRoutes.WAREHOUSE_MY_PRODUCT_DETAILS_WITHOUT_ID
                  }${product?.relatedProduct?.id?.toString()}
              `}
                >
                  {`${t('app.id')} ${getProductBarcodeTitle(
                    product?.relatedProduct?.barcodeId as string,
                  )}`}
                </Link>
                <Typography className={classes.productTitle} align='left'>{`${t(
                  'app.sku',
                )}: ${product?.relatedProduct?.sku}`}</Typography>
              </Box>
            </Box>
          </div>
        ))}
        <AdditionalService
          comment={order?.comment}
          message={order?.message}
          isWrap={order?.wrap !== wrappers[0].name}
          giftWrap={order?.giftWrap}
        />
      </TableCell>
      <TableCell align='center'>
        {order?.actualAmount
          ? `${order?.actualAmount} ${
              order?.orderCostCurrency || CURRENT_APP_CURRENCY
            }`
          : null}
      </TableCell>
      <TableCell align='center'>
        {(order.warehouseStatus === ShipmentWarehouseStatus.Shipped ||
          order.warehouseStatus === ShipmentWarehouseStatus.Cancelled) &&
          order.trackNumber &&
          order.carrierCode && (
            <TrackTrackNumberComponent
              carrierCode={order.carrierCode}
              trackNumber={order.trackNumber}
            />
          )}
      </TableCell>
      <TableCell align='center'>
        <ConvertWarehouseStatusIdToColorForAdmin
          status={order?.warehouseStatus as string}
        />
      </TableCell>
      <TableCell align='center'>
        {t(PAYMENT_ORDER_STATUS_BY_ID[Number(order?.paymentStatus?.id)])}
      </TableCell>
      <TableCell align='center'>
        <Select value={t('app.action')}>
          <MenuItem
            value={t('app.action')}
            disabled
            style={{ display: 'none' }}
          >
            {t('app.action')}
          </MenuItem>
          {options?.map((option) => (
            <ActionsItem
              {...option}
              key={option.title}
              itemLinkName={classes.actionMenuItem}
              linkClassName={classes.actionLink}
              iconClassName={classes.actionIcon}
              redColorIconClassName={classes.actionIconCancel}
              greenColorIconClassName={classes.actionIconWatch}
              blueColorIconClassName={classes.actionIconSend}
            />
          ))}
        </Select>
      </TableCell>
    </TableRow>
  );
};

export default ShipmentFromWarehouseTableRowComponent;
