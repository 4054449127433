import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import useStyles from './styles';
import { Link, useParams } from 'react-router-dom';
import {
  AvailableWarehouseProduct,
  Change_Product_Availability_Operation,
  Product,
  useChangeProductAvailabilityMutation,
  useGetAvailableWarehouseProductByIdLazyQuery,
  useGetCurrentUserQuery,
  useGetMyProductByIdQuery,
  useGetUserLazyQuery,
  useGetWarehouseProductLogByProductIdLazyQuery,
  useGetWarehousesListQuery,
  useGetWarehouseZoneCellsLazyQuery,
  useSetProductActualDimensionsMutation,
  Warehouse,
  WarehouseProductLog,
  WarehouseZoneCell,
} from '../../../../generated/graphql';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import iconBarcode from '../../../../assets/img/icon_barcode_button.svg';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import { useSnackbar } from 'notistack';
import { BoxCentered } from '../../../../components/BoxCentered/BoxCentered';
import { ShowLoadingText } from '../../../../utils/helperComponents';
import ModalBox from '../../../../components/ModalBox/ModalBox';
import { Edit as EditIcon } from '@material-ui/icons';
import { FastField, FieldProps, Formik } from 'formik';
import { LIMIT_ITEM_PAGE, TRoutes } from '../../../../utils/helpers';
import moment from 'moment';
import { DATE_FORMAT, DEBOUNCE } from '../../../../utils/constants';
import HistoryOfMovementProductsTable from '../../../../components/HistoryOfMovementProductsTable/HistoryOfMovementProductsTable';
import { actualDimensionsSchema } from '../../../../utils/validationSchemes';
import { debounce } from 'debounce';
import { Autocomplete } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import PhotoComponent from '../../../../components/PhotoComponent/PhotoComponent';

const initialValues = (product: any) => {
  const initialValues = {
    id: +product?.id,
    width: +product?.actualDimensions?.width || '0',
    height: +product?.actualDimensions?.height || '0',
    length: +product?.actualDimensions?.length || '0',
    weight: +product?.actualDimensions?.weight || '0',
  };
  return initialValues;
};

const WAREHOUSE_ZONE_CELLS_LIMIT = 20;

const MyProductDetails = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { t } = useTranslation();

  const isMediaQueryXs = useMediaQuery(theme.breakpoints.down('xs'));
  const { id } = useParams<{ id: string }>();
  const [product, setPropuct] = useState<Product | null>(null);
  const [isOpenSetActualDimensionsModal, setIsOpenSetActualDimensionsModal] =
    useState(false);
  const [warehouseList, setWarehouseList] = useState<Warehouse[]>([]);
  const [isOpenProductOperation, setIsOpenProductOperation] = useState(false);
  const [isAddProductOperation, setisAddProductOperation] = useState(true);
  const [warehouse, setWarehouse] = useState<Warehouse | null>(null);
  const [availabilityProduct, setAvailabilityProduct] =
    useState<AvailableWarehouseProduct | null>(null);
  const [warehouseId, setWarehouseId] = useState(0);
  const [isShowStatistics, setIsShowStatistics] = useState(false);
  const [filter, setFilter] = useState({
    page: 1,
  });
  const [searchCells, setSearchCells] = useState('');
  const [warehouseZoneCellsOffset, setwarehouseZoneCellsOffset] = useState(0);
  const [warehouseZoneCellsOptions, setwarehouseZoneCellsOptions] = useState<
    WarehouseZoneCell[]
  >([]);

  const [getWarehouseZoneCells, { data: cellsData, loading: cellsLoading }] =
    useGetWarehouseZoneCellsLazyQuery({
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    });

  const { loading, error, data, refetch } = useGetMyProductByIdQuery({
    variables: { productId: +id },
  });

  const { data: currentUserData } = useGetCurrentUserQuery();

  const { data: warehouses, error: warehousesError } =
    useGetWarehousesListQuery();

  const [getUserLazyQuery, { data: userData, error: userError }] =
    useGetUserLazyQuery();

  const [
    getAvaliableProductLazy,
    {
      loading: avaliableProductLoading,
      error: avaliableProductError,
      data: avaliableProductData,
    },
  ] = useGetAvailableWarehouseProductByIdLazyQuery();

  const [setProductActualDimensionsMutation] =
    useSetProductActualDimensionsMutation();

  const [
    changeProductAvalibilityMutation,
    { loading: changeProductAvalibilityMutationLoading },
  ] = useChangeProductAvailabilityMutation();

  const [
    warehouseProductLogByProductIdLazyQuery,
    {
      data: warehouseProductLogByProductIdData,
      loading: warehouseProductLogByProductIdLoading,
    },
  ] = useGetWarehouseProductLogByProductIdLazyQuery();

  if (error) enqueueSnackbar(error.message, { variant: 'error' });
  if (userError) enqueueSnackbar(userError.message, { variant: 'error' });
  avaliableProductError?.message &&
    enqueueSnackbar(avaliableProductError.message, { variant: 'error' });
  warehousesError?.message &&
    enqueueSnackbar(warehousesError.message, { variant: 'error' });

  useEffect(() => {
    if (!!warehouseId && id && isShowStatistics) {
      warehouseProductLogByProductIdLazyQuery({
        variables: {
          warehouseId: warehouseId.toString(),
          productId: id,
          offset: (filter.page - 1) * LIMIT_ITEM_PAGE,
          limit: LIMIT_ITEM_PAGE,
        },
        onError: (error) => {
          if (error) {
            enqueueSnackbar(error.message, { variant: 'error' });
          }
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [warehouseId, id, isShowStatistics, filter]);

  useEffect(() => {
    if (currentUserData?.currentUser?.role === 'SUPER_ADMIN') return;
    if (isOpenProductOperation)
      getWarehouseZoneCells({
        variables: {
          search: searchCells,
          limit: WAREHOUSE_ZONE_CELLS_LIMIT,
          offset: 0,
        },
        onCompleted: (response) => {
          setwarehouseZoneCellsOptions(
            response.getWarehouseZoneCells?.rows as WarehouseZoneCell[],
          );
          setwarehouseZoneCellsOffset(WAREHOUSE_ZONE_CELLS_LIMIT);
        },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCells, isOpenProductOperation]);

  useEffect(() => {
    if (
      currentUserData?.currentUser?.role === 'SUPER_ADMIN' ||
      !currentUserData
    ) {
      return;
    }
    setWarehouseId(Number(currentUserData?.currentUser?.warehouse?.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUserData]);

  useEffect(() => {
    data?.getMyProductById &&
      //@ts-ignore
      setPropuct(data?.getMyProductById);
  }, [data]);

  useEffect(() => {
    product &&
      //@ts-ignore
      getUserLazyQuery({
        variables: {
          id: product.userId as number,
        },
      });
    // eslint-disable-next-line
  }, [product]);

  useEffect(() => {
    if (!warehouses?.getWarehousesList?.rows) return;
    warehouses?.getWarehousesList?.rows &&
      setWarehouseList(warehouses?.getWarehousesList?.rows as Warehouse[]);
  }, [warehouses]);

  useEffect(() => {
    setAvailabilityProduct(
      avaliableProductData?.getAvailableWarehouseProductById as AvailableWarehouseProduct,
    );
    // eslint-disable-next-line
  }, [warehouseId, avaliableProductData]);

  const refetchAvaliableProduct = () => {
    getAvaliableProductLazy({
      variables: { warehouseId: warehouseId?.toString(), productId: id },
    });
  };

  useEffect(() => {
    if (
      warehouseId === 0 &&
      currentUserData?.currentUser?.role !== 'SUPER_ADMIN'
    )
      return;
    refetchAvaliableProduct();
    // eslint-disable-next-line
  }, [warehouseId]);

  useEffect(() => {
    if (currentUserData?.currentUser?.role !== 'SUPER_ADMIN') return;
    if (!warehouse) {
      setWarehouseId(0);
      return;
    }
    setWarehouseId(warehouse?.id as number);
    // eslint-disable-next-line
  }, [warehouse, currentUserData]);

  const handleSubmitActualDimensions = (values: any) => {
    setProductActualDimensionsMutation({
      variables: {
        id: +values?.id,
        width: +values?.width,
        height: +values?.height,
        length: +values?.length,
        weight: +values?.weight,
      },
    })
      .then((response) => {
        enqueueSnackbar(t('app.successfulChangeDimensionsWeight'), {
          variant: 'success',
        });
        refetch();
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      })
      .finally(() => {
        setIsOpenSetActualDimensionsModal(false);
      });
  };

  const handleChangechangeProductAvalibility = (values: any) => {
    const input = {
      productId: Number(id),
      warehouseId,
      quantity: values.quantity,
      operation: isAddProductOperation
        ? Change_Product_Availability_Operation.Refill
        : Change_Product_Availability_Operation.WriteOff,
      comment: values.comment,
      cellId: values.cellId,
    };
    changeProductAvalibilityMutation({
      variables: {
        input,
      },
    })
      .then((res) => {
        enqueueSnackbar(
          isAddProductOperation
            ? t('app.successfulAddProduct')
            : t('app.successfulWriteOffProduct'),
          {
            variant: 'success',
          },
        );
      })
      .catch((err) => {
        enqueueSnackbar(err.message, {
          variant: 'error',
        });
      })
      .finally(() => {
        setIsOpenProductOperation(false);
        refetchAvaliableProduct();
        setSearchCells('');
      });
  };

  const loadMoreWarehouseZoneCells = () => {
    getWarehouseZoneCells({
      variables: {
        search: searchCells,
        limit: WAREHOUSE_ZONE_CELLS_LIMIT,
        offset: warehouseZoneCellsOffset,
      },
      onCompleted: (response) => {
        setwarehouseZoneCellsOptions((prev) => [
          ...prev,
          ...(response.getWarehouseZoneCells?.rows as WarehouseZoneCell[]),
        ]);
        setwarehouseZoneCellsOffset(
          (prev) => prev + WAREHOUSE_ZONE_CELLS_LIMIT,
        );
      },
    });
  };

  return (
    <>
      {!loading ? (
        <>
          <Typography variant='h2' align='center' className={classes.mainTitle}>
            {t('app.viewProduct')}
          </Typography>
          <List>
            <Grid
              container
              spacing={0}
              alignItems='stretch'
              style={{ marginBottom: '24px', marginTop: '24px' }}
            >
              <Grid item sm={6} xs={12}>
                <ListItem className={classes.itemInfo}>
                  ID:{' '}
                  {product?.barcodeId?.substring(
                    0,
                    product?.barcodeId?.length - 4,
                  )}
                </ListItem>
                <ListItem className={classes.itemInfo}>
                  {t('app.created')}:{' '}
                  {moment(Number(product?.createdAt)).format(DATE_FORMAT)}
                </ListItem>
                <ListItem className={classes.itemInfo}>
                  {t('app.orderClient')}:&nbsp;
                  <Link
                    to={TRoutes.PAYMENT_STATISTICS_PER_USER.replace(
                      ':userId',
                      product?.userId?.toString() as string,
                    )}
                  >
                    {userData?.getUser?.name}
                  </Link>
                </ListItem>
                <ListItem className={classes.itemInfo}>
                  {t('app.productName')}: {product?.name}
                </ListItem>
                {product?.sku && (
                  <ListItem className={classes.itemInfo}>
                    {`${t('app.sku')}: ${product?.sku}`}
                  </ListItem>
                )}
                {product?.fnsku && (
                  <ListItem className={classes.itemInfo}>
                    FNSKU: {product?.fnsku}
                  </ListItem>
                )}
                {product?.upc && (
                  <ListItem className={classes.itemInfo}>
                    UPC: {product?.upc}
                  </ListItem>
                )}
                <Divider />

                {currentUserData?.currentUser?.role === 'SUPER_ADMIN' ? (
                  <>
                    <ListItem className={classes.itemInfo}>
                      {t('app.warehouse')}
                    </ListItem>
                    <FormControl variant='standard'>
                      <Select
                        className={classes.warehouseSelect}
                        id='warehouse'
                        value={warehouse ? warehouse : 0}
                        onChange={(e) => {
                          setWarehouse(e.target.value as Warehouse);
                        }}
                      >
                        <MenuItem value={0}>{t('app.allWarehouses')}</MenuItem>
                        {warehouseList?.map((item: any) => (
                          <MenuItem key={item.code} value={item}>
                            {item.code}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </>
                ) : null}
                {!avaliableProductLoading ? (
                  <>
                    <ListItem className={classes.itemInfo}>
                      {t('app.inTransit')}:{' '}
                      {availabilityProduct?.inTransitQuantity
                        ? availabilityProduct.inTransitQuantity
                        : 0}
                    </ListItem>
                    <ListItem className={classes.itemInfo}>
                      {t('app.available')}:{' '}
                      {availabilityProduct?.availableQuantity
                        ? availabilityProduct?.availableQuantity
                        : 0}
                    </ListItem>
                    <ListItem className={classes.itemInfo}>
                      {t('app.reserved')}:{' '}
                      {availabilityProduct?.reservedQuantity
                        ? availabilityProduct?.reservedQuantity
                        : 0}
                    </ListItem>
                  </>
                ) : (
                  <BoxCentered>
                    <ShowLoadingText name={t('app.product')} />
                  </BoxCentered>
                )}
              </Grid>
              <Grid item sm={6} xs={12}>
                <ListItem className={classes.itemInfo}>
                  <Box
                    width={'100%'}
                    display={'flex'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <PhotoComponent
                      photoName={product?.photo}
                      width={'338px'}
                    />
                  </Box>
                </ListItem>
              </Grid>
            </Grid>
            <Divider />
            <ListItem className={classes.itemInfo}>
              {`${t('app.cost')}: ${product?.declaredValue}`}
            </ListItem>
            <Divider />
            <ListItem className={classes.itemInfo}>
              {t('app.dimensionsWeightClaimed')}:{' '}
              {product?.declaredDimensions?.width}
              {' x '}
              {product?.declaredDimensions?.length}
              {' x '}
              {product?.declaredDimensions?.height} {t('app.cm')},{' '}
              {product?.declaredDimensions?.weight} {t('app.kg')}
            </ListItem>
            <ListItem className={classes.itemInfo}>
              <span>
                {t('app.dimensionsWeightActual')}:{' '}
                {product?.actualDimensions?.width
                  ? product?.actualDimensions?.width
                  : 0}{' '}
                x{' '}
                {product?.actualDimensions?.length
                  ? product?.actualDimensions?.length
                  : 0}{' '}
                x{' '}
                {product?.actualDimensions?.height
                  ? product?.actualDimensions?.height
                  : 0}{' '}
                {t('app.cm')},{' '}
                {product?.actualDimensions?.weight
                  ? product?.actualDimensions?.weight
                  : 0}{' '}
                {t('app.kg')}
              </span>

              <IconButton
                size='small'
                className={classes.btnIcon}
                onClick={() => {
                  setIsOpenSetActualDimensionsModal(true);
                }}
              >
                <Tooltip
                  title={`${t('app.changeActualWeightProduct')}${product?.id}`}
                >
                  <EditIcon className={classes.linkIcon} />
                </Tooltip>
              </IconButton>
            </ListItem>
          </List>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align='center'>{t('app.description')}</TableCell>
                  <TableCell align='center'>{t('app.quantitySm')}</TableCell>
                  <TableCell align='center'>{t('app.weightKg')}</TableCell>
                  <TableCell align='center'>{t('app.price')}</TableCell>
                  <TableCell align='center'>
                    {t('app.countryOfOrigin')}
                  </TableCell>
                  <TableCell align='center'>{t('app.brand')}</TableCell>
                  <TableCell align='center'>{t('app.hsCode')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell align='center'>
                    {product?.detailsDescriptionRU} /
                    {product?.detailsDescriptionEN}
                  </TableCell>
                  <TableCell align='center'>
                    {product?.productAvailabilityQuantity?.availableQuantity}
                  </TableCell>
                  <TableCell align='center'>
                    {product?.actualDimensions?.weight ||
                      product?.declaredDimensions?.weight}
                  </TableCell>
                  <TableCell align='center'>{`${product?.declaredValue} ${product?.currency}`}</TableCell>
                  <TableCell align='center'>
                    {product?.country?.nameEng}
                  </TableCell>
                  <TableCell align='center'>{product?.trademark}</TableCell>

                  <TableCell align='center'>
                    {product?.code && (
                      <Tooltip
                        title={`${t('app.opensInNewWindowOnTnvedInfo')}`}
                      >
                        <a
                          target='_blank'
                          rel='noopener noreferrer'
                          href={`https://tnved.info/TnvedTree?Code=${product?.code}`}
                        >
                          {product?.code}
                        </a>
                      </Tooltip>
                    )}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <div className={classes.wrapButtons}>
            {currentUserData?.currentUser?.role !== 'SUPER_ADMIN' ? (
              <>
                <Tooltip title={t('app.clickReplenishInventory')}>
                  <Button
                    fullWidth={isMediaQueryXs}
                    variant='contained'
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setIsOpenProductOperation(true);
                      setisAddProductOperation(true);
                    }}
                  >
                    Пополнить
                  </Button>
                </Tooltip>
                <Divider
                  orientation={isMediaQueryXs ? 'horizontal' : 'vertical'}
                />
                <Tooltip title={t('app.clickWriteOffInventory')}>
                  <Button
                    fullWidth={isMediaQueryXs}
                    variant='contained'
                    startIcon={<RemoveIcon />}
                    onClick={() => {
                      setIsOpenProductOperation(true);
                      setisAddProductOperation(false);
                    }}
                  >
                    {t('app.debitT')}
                  </Button>
                </Tooltip>
                <Divider
                  orientation={isMediaQueryXs ? 'horizontal' : 'vertical'}
                />
              </>
            ) : null}

            <Tooltip title={t('app.clickOpenProductHistory')}>
              <Button
                fullWidth={isMediaQueryXs}
                variant='contained'
                onClick={() => {
                  setIsShowStatistics((prev) => !prev);
                }}
                startIcon={<InsertChartIcon />}
              >
                {t('app.movementHistory')}
              </Button>
            </Tooltip>
            <Divider orientation={isMediaQueryXs ? 'horizontal' : 'vertical'} />
            <Tooltip title={t('app.clickOpenBarcode')}>
              <Button
                fullWidth={isMediaQueryXs}
                variant='contained'
                onClick={() => {
                  window.open(
                    `${process.env.REACT_APP_API_DOMAIN}/api/getBarcode/${product?.barcodeId}`,
                  );
                }}
              >
                <img
                  src={iconBarcode}
                  alt='barcode'
                  className={`${classes.actionIcon}`}
                  style={{ width: 20, height: 20 }}
                />
                {t('app.barcode')}
              </Button>
            </Tooltip>
          </div>
          {isShowStatistics && (
            <>
              {!!warehouseId ? (
                <HistoryOfMovementProductsTable
                  history={
                    warehouseProductLogByProductIdData
                      ?.getWarehouseProductLogByProductId
                      ?.rows as WarehouseProductLog[]
                  }
                  count={
                    warehouseProductLogByProductIdData
                      ?.getWarehouseProductLogByProductId?.count as number
                  }
                  page={filter.page}
                  setFilter={setFilter}
                  loading={warehouseProductLogByProductIdLoading}
                />
              ) : (
                <BoxCentered>
                  <Typography>{t('app.selectWarehouse')}</Typography>
                </BoxCentered>
              )}
            </>
          )}
        </>
      ) : (
        <BoxCentered>
          <ShowLoadingText name={(t('app.product') || '').toLowerCase()} />
        </BoxCentered>
      )}
      <ModalBox
        isOpen={isOpenProductOperation}
        setOpen={setIsOpenProductOperation}
      >
        <Formik
          initialValues={{ quantity: 1, comment: '', cellId: '' }}
          onSubmit={handleChangechangeProductAvalibility}
        >
          {({ setFieldValue, handleSubmit, values }) => (
            <>
              <Typography>
                {isAddProductOperation
                  ? t('app.productReplenishment')
                  : t('app.productWriteOff')}
              </Typography>
              <Box display={'flex'} flexDirection={'column'}>
                <FastField name={'comment'}>
                  {({ field: { value, ...field }, meta }: FieldProps) => {
                    return (
                      <FormControl
                        className={classes.inputLabelOperation}
                        error={!!(meta.touched && meta.error && value !== 0)}
                      >
                        <InputLabel shrink={false} htmlFor={`input-comment`}>
                          {t('app.purpose')}
                        </InputLabel>
                        <Input
                          style={{ marginBottom: 24 }}
                          disableUnderline
                          id={`input-comment`}
                          {...field}
                          defaultValue={value}
                          onChange={(e) => {
                            setFieldValue('comment', e.target.value);
                          }}
                          value={value}
                        />
                        {meta.touched && meta.error && value !== '' && (
                          <FormHelperText>{meta.error}</FormHelperText>
                        )}
                      </FormControl>
                    );
                  }}
                </FastField>
                <Box display='flex' justifyContent='space-between'>
                  <FastField name={'quantity'}>
                    {({ field: { value, ...field }, meta }: FieldProps) => {
                      return (
                        <FormControl
                          className={classes.inputLabelPackage}
                          error={!!(meta.touched && meta.error && value !== 0)}
                        >
                          <InputLabel shrink={false} htmlFor={`input-quantity`}>
                            {t('app.quantity')}
                          </InputLabel>
                          <Input
                            style={{ marginRight: 24 }}
                            disableUnderline
                            id={`input-quantity`}
                            {...field}
                            inputProps={{
                              type: 'number',
                              step: 1,
                              min: 0,
                            }}
                            startAdornment={
                              isAddProductOperation ? (
                                <AddIcon />
                              ) : (
                                <RemoveIcon />
                              )
                            }
                            defaultValue={value}
                            onChange={(e) => {
                              setFieldValue('quantity', +e.target.value);
                            }}
                            value={value}
                          />
                          {meta.touched && meta.error && value !== 0 && (
                            <FormHelperText>{meta.error}</FormHelperText>
                          )}
                        </FormControl>
                      );
                    }}
                  </FastField>

                  <FormControl variant='standard'>
                    <InputLabel
                      className={classes.inputLabelPackage}
                      htmlFor='warehouseCellId'
                    >
                      {t('app.storageCell')}
                    </InputLabel>
                    <Autocomplete
                      id='warehouseCellId'
                      disableClearable
                      className={classes.inputLabelOperation}
                      loadingText={`${t('app.loading')}...`}
                      noOptionsText={t('app.noHints')}
                      closeText={t('app.close')}
                      options={
                        (warehouseZoneCellsOptions as WarehouseZoneCell[]) || []
                      }
                      loading={cellsLoading}
                      getOptionLabel={(option) => {
                        return `${option?.name}`;
                      }}
                      renderOption={(cell) => {
                        return <MenuItem key={cell?.id}>{cell?.name}</MenuItem>;
                      }}
                      ListboxProps={{
                        onScroll: (event: React.UIEvent<HTMLUListElement>) => {
                          const listboxNode = event.currentTarget;
                          if (
                            listboxNode.scrollTop + listboxNode.clientHeight >=
                              listboxNode.scrollHeight &&
                            Number(cellsData?.getWarehouseZoneCells?.count) >
                              warehouseZoneCellsOptions.length
                          ) {
                            loadMoreWarehouseZoneCells();
                          }
                        },
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                              <>
                                {cellsLoading && (
                                  <CircularProgress color='inherit' size={20} />
                                )}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      onChange={(event, newValue) => {
                        const selectedCellId = warehouseZoneCellsOptions?.find(
                          (cell) => cell?.name === newValue.name,
                        )?.id;
                        setFieldValue('cellId', selectedCellId);
                      }}
                      onInputChange={(e, newValue) => {
                        setSearchCells(newValue);
                      }}
                    />
                  </FormControl>
                </Box>
                <div className={classes.boxModalButtons}>
                  <Button
                    style={{ marginRight: '24px' }}
                    variant='contained'
                    color='secondary'
                    onClick={() => {
                      setIsOpenProductOperation(false);
                      setSearchCells('');
                    }}
                  >
                    {t('app.close')}
                  </Button>
                  <Button
                    variant='contained'
                    onClick={handleSubmit as () => void}
                    disabled={
                      changeProductAvalibilityMutationLoading ||
                      !values.cellId ||
                      !values.comment
                    }
                  >
                    {isAddProductOperation
                      ? t('app.refill')
                      : t('app.writeOffNow')}
                  </Button>
                </div>
              </Box>
            </>
          )}
        </Formik>
      </ModalBox>
      <ModalBox
        isOpen={isOpenSetActualDimensionsModal}
        setOpen={setIsOpenSetActualDimensionsModal}
      >
        <Formik
          initialValues={initialValues(product)}
          validationSchema={actualDimensionsSchema}
          onSubmit={handleSubmitActualDimensions}
        >
          {({ setFieldValue, handleSubmit }) => {
            const setFieldValueDebounce = debounce(
              (name: string, value: string) => {
                setFieldValue(name, value);
              },
              DEBOUNCE,
            );
            const handlerChange = (
              e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
            ) => {
              setFieldValueDebounce(e.target.name, e.target.value);
            };
            return (
              <>
                <div className={classes.packageItem}>
                  <div className={classes.root}>
                    <Grid container spacing={5}>
                      <Grid item xs={12} sm={3}>
                        <FastField name={'weight'}>
                          {({
                            field: { value, ...field },
                            meta,
                          }: FieldProps) => {
                            return (
                              <FormControl
                                className={classes.inputLabelPackage}
                                error={
                                  !!(meta.touched && meta.error && value !== 0)
                                }
                              >
                                <InputLabel
                                  shrink={false}
                                  htmlFor={`input-weight`}
                                >
                                  Вес
                                </InputLabel>
                                <Input
                                  disableUnderline
                                  id={`input-weight`}
                                  {...field}
                                  inputProps={{
                                    type: 'number',
                                    step: 0.01,
                                    min: 0,
                                  }}
                                  startAdornment={
                                    <InputAdornment position='end'>
                                      кг
                                    </InputAdornment>
                                  }
                                  defaultValue={value}
                                  onChange={handlerChange}
                                  value={value}
                                />
                                {meta.touched && meta.error && value !== 0 && (
                                  <FormHelperText>{meta.error}</FormHelperText>
                                )}
                              </FormControl>
                            );
                          }}
                        </FastField>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FastField name={'length'}>
                          {({
                            field: { value, ...field },
                            meta,
                          }: FieldProps) => (
                            <FormControl
                              className={classes.inputLabelPackage}
                              error={
                                meta.touched && !!meta.error && value !== 0
                              }
                            >
                              <InputLabel
                                shrink={false}
                                htmlFor={`input-length`}
                              >
                                {t('app.length')}
                              </InputLabel>
                              <Input
                                disableUnderline
                                id={`input-length`}
                                {...field}
                                inputProps={{
                                  type: 'number',
                                  min: 0,
                                  step: 0.1,
                                }}
                                startAdornment={
                                  <InputAdornment position='end'>
                                    {t('app.cm')}
                                  </InputAdornment>
                                }
                                defaultValue={value}
                                onChange={handlerChange}
                                value={value}
                              />
                              {meta.touched && !!meta.error && value !== 0 && (
                                <FormHelperText>{meta.error}</FormHelperText>
                              )}
                            </FormControl>
                          )}
                        </FastField>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FastField name={'width'}>
                          {({
                            field: { value, ...field },
                            meta,
                          }: FieldProps) => (
                            <FormControl
                              className={classes.inputLabelPackage}
                              error={
                                meta.touched && !!meta.error && value !== 0
                              }
                            >
                              <InputLabel
                                shrink={false}
                                htmlFor={`input-width`}
                              >
                                {t('app.width')}
                              </InputLabel>
                              <Input
                                disableUnderline
                                id={`input-width`}
                                {...field}
                                inputProps={{
                                  type: 'number',
                                  min: 0,
                                  step: 0.1,
                                }}
                                startAdornment={
                                  <InputAdornment position='end'>
                                    {t('app.cm')}
                                  </InputAdornment>
                                }
                                defaultValue={value}
                                onChange={handlerChange}
                                value={value}
                              />
                              {meta.touched && !!meta.error && value !== 0 && (
                                <FormHelperText>{meta.error}</FormHelperText>
                              )}
                            </FormControl>
                          )}
                        </FastField>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <FastField name={'height'}>
                          {({
                            field: { value, ...field },
                            meta,
                          }: FieldProps) => (
                            <FormControl
                              className={classes.inputLabelPackage}
                              error={
                                meta.touched && !!meta.error && value !== 0
                              }
                            >
                              <InputLabel shrink={false} htmlFor={`height`}>
                                {t('app.height')}
                              </InputLabel>
                              <Input
                                disableUnderline
                                id={`height`}
                                {...field}
                                inputProps={{
                                  type: 'number',
                                  min: 0,
                                  step: 0.1,
                                }}
                                startAdornment={
                                  <InputAdornment position='end'>
                                    {t('app.cm')}
                                  </InputAdornment>
                                }
                                defaultValue={value}
                                onChange={handlerChange}
                                value={value}
                              />
                              {meta.touched && !!meta.error && value !== 0 && (
                                <FormHelperText>{meta.error}</FormHelperText>
                              )}
                            </FormControl>
                          )}
                        </FastField>
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <div className={classes.boxModalButtons}>
                  <Button
                    variant='contained'
                    color='secondary'
                    onClick={() => {
                      setIsOpenSetActualDimensionsModal(false);
                    }}
                  >
                    {t('app.close')}
                  </Button>
                  <Button
                    variant='contained'
                    onClick={handleSubmit as () => {}}
                  >
                    {t('app.change')}
                  </Button>
                </div>
              </>
            );
          }}
        </Formik>
      </ModalBox>
    </>
  );
};

export default MyProductDetails;
