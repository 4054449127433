import { B2BShipmentMethodTypeEnum } from './../generated/graphql';
import * as Yup from 'yup';
import {
  ERRORS,
  MAX_LENGTH_DESCRIPTION_DHL,
  MAX_PARCEL_WEIGHT,
} from './constants';
import { checkAllowedCharacters } from './helpers';
import { t } from 'i18next';
import { WarehouseB2BStatus } from '../generated/graphql';

export const signIn = () =>
  Yup.object().shape({
    email: Yup.string()
      .email(t('app.invalidEmail'))
      .required(t(ERRORS.FIELD_REQUIRED)),
    password: Yup.string()
      .trim()
      .required(t(ERRORS.FIELD_REQUIRED))
      .min(8, t('app.passwordLength', { value: 8 })),
  });

export const formUser = () =>
  Yup.object().shape({
    name: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    email: Yup.string()
      .required(t(ERRORS.FIELD_REQUIRED))
      .email(t('app.invalidEmail')),
    company: Yup.string().nullable(),
    country: Yup.string().nullable().required(t(ERRORS.FIELD_REQUIRED)),
    address: Yup.string().nullable(),
    address2: Yup.string().nullable(),
    address3: Yup.string().nullable(),
    zipCode: Yup.string().nullable(),
    city: Yup.string().nullable(),
    state: Yup.string().nullable(true),
    phone: Yup.string().nullable(),
    eoriCode: Yup.string().nullable(),
  });

export const formManagerSchema = () =>
  Yup.object().shape({
    name: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    email: Yup.string()
      .required(t(ERRORS.FIELD_REQUIRED))
      .email(t('app.invalidEmail')),
    password: Yup.string()
      .nullable()
      .min(8, t('app.passwordLength', { value: 8 })),
    warehouseId: Yup.number().nullable(),
  });

export const formWarehouseSchema = () =>
  Yup.object().shape({
    name: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    company: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    address: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    phone: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    countryId: Yup.number().required(t(ERRORS.FIELD_REQUIRED)),
    zipCode: Yup.number().required(t(ERRORS.FIELD_REQUIRED)),
    city: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    state: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
  });

export const formContact = (isAddress: boolean) => () =>
  Yup.object().shape({
    ...(isAddress
      ? { contactName: Yup.string().required(t(ERRORS.FIELD_REQUIRED)) }
      : {}),
    name: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    company: Yup.string().nullable().required(t(ERRORS.FIELD_REQUIRED)),
    country: Yup.string().nullable().required(t(ERRORS.FIELD_REQUIRED)),
    address: Yup.string().nullable().required(t(ERRORS.FIELD_REQUIRED)),
    ...(!isAddress ? { address2: Yup.string().nullable().default('') } : {}),
    ...(!isAddress ? { address3: Yup.string().nullable().default('') } : {}),
    zipCode: Yup.string().nullable().default(''),
    city: Yup.string().nullable().required(t(ERRORS.FIELD_REQUIRED)),
    state: Yup.string().nullable(true),
    phone: Yup.string().nullable().required(t(ERRORS.FIELD_REQUIRED)),
    email: Yup.string().nullable().email(t('app.invalidEmail')),
    ...(!isAddress ? { innKppCode: Yup.string().nullable() } : {}),
    ...(!isAddress ? { remark: Yup.string().nullable().default('') } : {}),
  });

export const parcel = () =>
  Yup.object().shape({
    sender: formContact(false)(),
    receiver: formContact(false)(),
    currency: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    packages: Yup.array().of(
      Yup.object().shape({
        weightKg: Yup.number()
          .typeError(t('app.nonIntegerNumber'))
          .positive(t(ERRORS.POSITIVE_NUMBER))
          .required(t(ERRORS.FIELD_REQUIRED)),
        lengthCm: Yup.number()
          .typeError(t('app.nonIntegerNumber'))
          .positive(t(ERRORS.POSITIVE_NUMBER))
          .required(t(ERRORS.FIELD_REQUIRED)),
        widthCm: Yup.number()
          .typeError(t('app.nonIntegerNumber'))
          .positive(t(ERRORS.POSITIVE_NUMBER))
          .required(t(ERRORS.FIELD_REQUIRED)),
        heightCm: Yup.number()
          .typeError(t('app.nonIntegerNumber'))
          .positive(t(ERRORS.POSITIVE_NUMBER))
          .required(t(ERRORS.FIELD_REQUIRED)),
        units: Yup.array().of(
          Yup.object().shape({
            nameRU: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
            nameEN: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
            tradeMark: Yup.string(),
            code: Yup.number()
              .max(
                Number.MAX_SAFE_INTEGER,
                `${t(ERRORS.MAX_VALUE)} ${Number.MAX_SAFE_INTEGER}`,
              )
              .nullable(),
            quantity: Yup.number()
              .typeError(t('app.mustBeNumber'))
              .positive(t(ERRORS.POSITIVE_NUMBER))
              .integer(t('app.mustBeInteger'))
              .required(t(ERRORS.FIELD_REQUIRED)),
            price: Yup.number()
              .positive(t(ERRORS.POSITIVE_NUMBER))
              .typeError(t('app.nonIntegerNumber'))
              .positive(t(ERRORS.POSITIVE_NUMBER))
              .required(t(ERRORS.FIELD_REQUIRED)),
            country: Yup.mixed().test({
              name: 'country',
              exclusive: true,
              message: t('app.chooseCountry'),
              test: (value: { iso: string }) => {
                if (value?.iso) {
                  return true;
                }
                return false;
              },
            }),
            netWeight: Yup.number()
              .positive(t(ERRORS.POSITIVE_NUMBER))
              .typeError(t('app.mustBeNumber'))
              .positive(t(ERRORS.POSITIVE_NUMBER))
              .required(t(ERRORS.FIELD_REQUIRED)),
            state: Yup.string(),
          }),
        ),
      }),
    ),
    senderMark: Yup.string().nullable().default(''),
    senderNotes: Yup.string().default(''),
    additionalInsurance: Yup.bool().default(false),
    typeDeliveryId: Yup.object({
      typeDeliveryId: Yup.number().required(t(ERRORS.FIELD_REQUIRED)),
      indexValue: Yup.number().required(t(ERRORS.FIELD_REQUIRED)),
    }),
    declaredWeight: Yup.number().nullable(),
    declaredAmount: Yup.number().nullable(),
    exportReason: Yup.string().nullable(),
    paymentOfTaxes: Yup.string().nullable(),
    vatKey: Yup.string().nullable(),
    vatValue: Yup.string().nullable(),
  });

export const consolidation = () =>
  Yup.object().shape({
    sender: formContact(false)(),
    receiver: formContact(false)(),
    currency: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    packages: Yup.array().of(
      Yup.object().shape({
        weightKg: Yup.number()
          .typeError(t('app.nonIntegerNumber'))
          .positive(t(ERRORS.POSITIVE_NUMBER))
          .required(t(ERRORS.FIELD_REQUIRED)),
        lengthCm: Yup.number()
          .typeError(t('app.nonIntegerNumber'))
          .positive(t(ERRORS.POSITIVE_NUMBER))
          .required(t(ERRORS.FIELD_REQUIRED)),
        widthCm: Yup.number()
          .typeError(t('app.nonIntegerNumber'))
          .positive(t(ERRORS.POSITIVE_NUMBER))
          .required(t(ERRORS.FIELD_REQUIRED)),
        heightCm: Yup.number()
          .typeError(t('app.nonIntegerNumber'))
          .positive(t(ERRORS.POSITIVE_NUMBER))
          .required(t(ERRORS.FIELD_REQUIRED)),
      }),
    ),
    senderMark: Yup.string().nullable().default(''),
    senderNotes: Yup.string().default(''),
    additionalInsurance: Yup.bool().default(false),
    typeDeliveryId: Yup.object({
      typeDeliveryId: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
      indexValue: Yup.number().required(t(ERRORS.FIELD_REQUIRED)),
    }),
    declaredWeight: Yup.number().nullable(),
    declaredAmount: Yup.number().nullable(),
    exportReason: Yup.string().nullable(),
    paymentOfTaxes: Yup.string().nullable(),
    vatKey: Yup.string().nullable(),
    vatValue: Yup.string().nullable(),
  });

export const parcelTrackSchema = () =>
  Yup.object().shape({
    trackNumber: Yup.string()
      .required(t(ERRORS.FIELD_REQUIRED))
      .test({
        test: function (value: []) {
          if (value && value.length >= 4 && checkAllowedCharacters(value)) {
            return true;
          } else if (value && value.length < 4) {
            return this.createError({
              message: t('app.trackingNumberLength', { value: 4 }),
            });
          } else {
            return this.createError({
              message: t('app.trackingNumberFormat'),
            });
          }
        },
      }),
    carrierCode: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
  });

export const settingsCalculatorSchema = () =>
  Yup.object().shape({
    emergencyFeeForWeight: Yup.string(),
    emergencyFeeMin: Yup.string(),
    remoteAreaForWeight: Yup.string(),
    remoteAreaMin: Yup.string(),
    fuelSurchargeDHL: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .min(0)
      .required(t(ERRORS.FIELD_REQUIRED)),
    fuelSurchargeFEDEX: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .min(0)
      .max(100)
      .required(t(ERRORS.FIELD_REQUIRED)),
    fuelSurchargeUPS: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .min(0)
      .max(100)
      .required(t(ERRORS.FIELD_REQUIRED)),
    commission: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .min(0)
      .max(100)
      .required(t(ERRORS.FIELD_REQUIRED)),
    vat: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .min(0)
      .max(100)
      .required(t(ERRORS.FIELD_REQUIRED)),
  });

export const settingsTariffsGridSchema = () =>
  Yup.object().shape({
    weight: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .min(0)
      .max(MAX_PARCEL_WEIGHT)
      .required(t(ERRORS.FIELD_REQUIRED))
      .test({
        test: function (weight: number) {
          if (weight && +weight && +weight % 0.5 === 0) {
            return true;
          } else {
            return this.createError({
              message: t('app.weightShouldMultiple'),
            });
          }
        },
      }),
    tariffA: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .min(0)
      .required(t(ERRORS.FIELD_REQUIRED)),
    tariffB: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .min(0)
      .required(t(ERRORS.FIELD_REQUIRED)),
    tariffC: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .min(0)
      .required(t(ERRORS.FIELD_REQUIRED)),
    tariffD: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .min(0)
      .required(t(ERRORS.FIELD_REQUIRED)),
    tariffE: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .min(0)
      .required(t(ERRORS.FIELD_REQUIRED)),
    tariffF: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .min(0)
      .required(t(ERRORS.FIELD_REQUIRED)),
    tariffG: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .min(0)
      .required(t(ERRORS.FIELD_REQUIRED)),
    tariffH: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .min(0)
      .required(t(ERRORS.FIELD_REQUIRED)),
  });

export const SenderAccountSchema = () =>
  Yup.object({
    serviceName: Yup.string().nullable(),
    siteId: Yup.string().nullable(),
    accountNumber: Yup.string().when('serviceName', {
      is: (val) => val !== 'EASYPOST',
      then: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
      otherwise: Yup.string().nullable(),
    }),
    accountName: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    password: Yup.string().nullable(),
    status: Yup.bool().default(true),
    requestorPostalCode: Yup.string().nullable(),
    requestorPhone: Yup.string().nullable(),
    requestorName: Yup.string().nullable(),
    requestorCountryCode: Yup.string().nullable(),
    requestorCity: Yup.string().nullable(),
    requestorAddress: Yup.string().nullable(),
    isTestMode: Yup.bool().default(true),
    easypostProdApiKey: Yup.string().when('serviceName', {
      is: (val) => val === 'EASYPOST',
      then: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
      otherwise: Yup.string().nullable(),
    }),
    easypostTestApiKey: Yup.string().when('serviceName', {
      is: (val) => val === 'EASYPOST',
      then: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
      otherwise: Yup.string().nullable(),
    }),
  });

export const CallACourierSchema = () =>
  Yup.object({
    senderAccountId: Yup.number().required(t(ERRORS.FIELD_REQUIRED)),
    closeTime: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    contactPhone: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    pieces: Yup.array()
      .of(
        Yup.object({
          weight: Yup.number()
            .positive(t(ERRORS.POSITIVE_NUMBER))
            .max(MAX_PARCEL_WEIGHT)
            .required(t(ERRORS.FIELD_REQUIRED)),
          length: Yup.number()
            .positive(t(ERRORS.POSITIVE_NUMBER))
            .required(t(ERRORS.FIELD_REQUIRED)),
          width: Yup.number().required(t(ERRORS.FIELD_REQUIRED)),
          height: Yup.number().required(t(ERRORS.FIELD_REQUIRED)),
        }),
      )
      .required('Пожалуйста, выберите заказы, которые должен забрать курьер!'),
    company: Yup.string(),
    packageLocation: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    pickupDate: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    readyByTime: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    contactName: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    contactId: Yup.number().required(t(ERRORS.FIELD_REQUIRED)),
  });

export const ParcelSendSchema = () =>
  Yup.object({
    shipmentDate: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    senderAccountId: Yup.number().required(t(ERRORS.FIELD_REQUIRED)),
    parcelId: Yup.number().required(t(ERRORS.FIELD_REQUIRED)),
    DHLInvoiceType: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    exportReason: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    contactId: Yup.number().nullable(),
    termsOfTrade: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    globalProductCode: Yup.string().required(
      'Рассчитайте стоимость и выберите тариф',
    ),
    customDescription: Yup.array().of(
      Yup.object({
        id: Yup.number().required(t(ERRORS.FIELD_REQUIRED)),
        description: Yup.string()
          .required()
          .max(
            MAX_LENGTH_DESCRIPTION_DHL,
            `Максимальная длина: ${MAX_LENGTH_DESCRIPTION_DHL}`,
          ),
      }),
    ),
  });

export const actualDimensionsSchema = () =>
  Yup.object({
    id: Yup.number().required(t(ERRORS.FIELD_REQUIRED)),
    weight: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .required(t(ERRORS.FIELD_REQUIRED)),
    length: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .required(t(ERRORS.FIELD_REQUIRED)),
    width: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .required(t(ERRORS.FIELD_REQUIRED)),
    height: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .required(t(ERRORS.FIELD_REQUIRED)),
  });

export const createZoneSchema = () =>
  Yup.object({
    name: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
  });

export const synchronizeProductSchema = () =>
  Yup.object({
    cellToId: Yup.number().required(t(ERRORS.FIELD_REQUIRED)),
  });

export const sendNotificationSchema = () =>
  Yup.object({
    topic: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    text: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
  });

export const createOrEditB2BShipmentPointSchema = () =>
  Yup.object({
    name: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    description: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
  });

export const createOrEditB2BServiceSchema = () =>
  Yup.object({
    code: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    title: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    description: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    costInUSD: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .typeError(t('app.nonIntegerNumber'))
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .required(t(ERRORS.FIELD_REQUIRED)),
  });

const B2bShipmentPackagingFormStep1ValidationSchemas = Yup.object().shape({
  warehouseStatus: Yup.string().test(
    'is-created',
    'app.b2bUnpickedError',
    (value) => {
      return (
        value === WarehouseB2BStatus.Picked ||
        value === WarehouseB2BStatus.BarcodeRequried ||
        value === WarehouseB2BStatus.ShipmentReady
      );
    },
  ),
});

export const B2bShipmentPackagingFormStep2ValidationSchemas =
  Yup.object().shape({
    cargoPlaces: Yup.array()
      .of(
        Yup.object().shape({
          width: Yup.number()
            .required('Width is required')
            .positive('Width must be greater than zero'),
          height: Yup.number()
            .required('Height is required')
            .positive('Height must be greater than zero'),
          length: Yup.number()
            .required('Length is required')
            .positive('Length must be greater than zero'),
          weight: Yup.number()
            .required('Weight is required')
            .positive('Weight must be greater than zero'),
        }),
      )
      .min(1, t(ERRORS.FIELD_REQUIRED)),
  });

const B2bShipmentPackagingFormStep3ValidationSchemas = Yup.object().shape({
  shipmentInfo: Yup.object().shape({
    typeMethod: Yup.string().required('Type method is required'),
    driverInfo: Yup.object().when('typeMethod', {
      is: B2BShipmentMethodTypeEnum.PicknPack,
      then: Yup.object().shape({
        carModel: Yup.string().required(ERRORS.FIELD_REQUIRED),
        carNumber: Yup.string().required(ERRORS.FIELD_REQUIRED),
        fullName: Yup.string().required(ERRORS.FIELD_REQUIRED),
        mobileNumber: Yup.string().required(ERRORS.FIELD_REQUIRED),
      }),
      otherwise: null,
    }),
  }),
});

const B2bShipmentPackagingFormStep4ValidationSchemas = Yup.object().shape({
  servicesWithCost: Yup.array()
    .of(
      Yup.object().shape({
        qty: Yup.number()
          .typeError(t('app.mustBeNumber'))
          .positive(t(ERRORS.POSITIVE_NUMBER))
          .integer(t('app.mustBeInteger'))
          .required(t(ERRORS.FIELD_REQUIRED)),
        costInUSD: Yup.number()
          .typeError(t('app.mustBeNumber'))
          .required(t(ERRORS.FIELD_REQUIRED))
          .min(0.01, t(ERRORS.POSITIVE_NUMBER)),
        id: Yup.number()
          .typeError(t('app.mustBeNumber'))
          .integer(t('app.mustBeInteger'))
          .required(t(ERRORS.FIELD_REQUIRED)),
        title: Yup.string().required(ERRORS.FIELD_REQUIRED),
      }),
    )
    .min(1, 'At least one service must be selected'),
});

export const B2bShipmentPackagingFormValidationSchemas = [
  B2bShipmentPackagingFormStep1ValidationSchemas,
  B2bShipmentPackagingFormStep2ValidationSchemas,
  B2bShipmentPackagingFormStep3ValidationSchemas,
  B2bShipmentPackagingFormStep4ValidationSchemas,
];

export const B2bShipmentCreateCargoPlaceModalFormStep1ValidationSchemas =
  Yup.object().shape({
    wrapWay: Yup.string().required(ERRORS.FIELD_REQUIRED),
    wrapType: Yup.string().required(ERRORS.FIELD_REQUIRED),
  });

export const B2bShipmentCreateCargoPlaceModalFormStep2ValidationSchemas =
  Yup.object().shape({
    cargoPlaces: Yup.array().of(
      Yup.object().shape({
        weight: Yup.number()
          .required(ERRORS.FIELD_REQUIRED)
          .min(1, ERRORS.POSITIVE_NUMBER),
        productsItems: Yup.array()
          .test(
            'has-positive-quantity',
            ERRORS.FIELD_REQUIRED,
            (productsItems) => {
              return productsItems?.some((item: any) => item?.quantity > 0);
            },
          )
          .required(ERRORS.FIELD_REQUIRED),
      }),
    ),
  });

export const B2bShipmentCreateCargoPlaceModalValidationSchemas = [
  B2bShipmentCreateCargoPlaceModalFormStep1ValidationSchemas,
  B2bShipmentCreateCargoPlaceModalFormStep2ValidationSchemas,
];

export const configurationValidationSchema = () =>
  Yup.object().shape({
    current_app_currency: Yup.string()
      .length(3, ERRORS.CURRENCY_CODE) // Должно быть ровно 3 символа
      .matches(/^[A-Z]{3}$/, ERRORS.CURRENCY_CODE)
      .required(ERRORS.FIELD_REQUIRED),
  });
