import React, { useEffect, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import useStyles from './styles';
import { useSnackbar } from 'notistack';
import { DEPARTURE_DATE_FILTER, LIMIT_ITEM_PAGE } from '../../utils/helpers';
import {
  LOCAL_STORAGE_KEYS,
  SHIPMENT_FROM_WAREHOUSE_TABLE_CELLS_ARRAY,
} from '../../utils/constants';
import {
  DeliveryService,
  useCancelWarehouseShipmentOrderMutation,
  useGetCurrentUserQuery,
  useGetWarehouseShipmentOrderAdditionalOptionsQuery,
  useGetWarehouseShipmentOrdersLazyQuery,
  Warehouse_Shipment_Order_Type,
  WarehouseShipmentOrder,
} from '../../generated/graphql';
import SendWarehouseShipmentOrder from '../../components/SendWarehouseShipmentOrder/SendWarehouseShipmentOrder';
import { useTranslation } from 'react-i18next';
import PaginationComponent from '../../components/PaginationComponent/PaginationComponent';
import { IFilters } from '../../utils/types';
import FilterSelectLimitItems from '../../components/FilterComponents/FilterSelectLimitItems/FilterSelectLimitItems';
import FilterSelectPaymentStatus from '../../components/FilterComponents/FilterSelectPaymentStatus/FilterSelectPaymentStatus';
import FilterSelectDeliveryStatus from '../../components/FilterComponents/FilterSelectDeliveryStatus/FilterSelectDeliveryStatus';
import FilterSelectDepartureDate from '../../components/FilterComponents/FilterSelectDepartureDate/FilterSelectDepartureDate';
import FilterSelectWarehouse from '../../components/FilterComponents/FilterSelectWarehouse/FilterSelectWarehouse';
import { QUERY_WAREHOUSE_SHIPMENT_ORDERS } from '../../GraphQL/queries/getWarehouseShipmentOrders';
import CancelWarehouseShipmentOrderModal from '../../components/Modals/CancelWarehouseShipmentOrderModal';
import TableBodyRequestHandler from '../../components/TableBodyRequestHandler/TableRequestHandler';
import ShipmentFromWarehouseTableRowComponent from '../../components/ShipmetsFromWarehouseComponents/ShipmentFromWarehouseTableRowComponent/ShipmentFromWarehouseTableRowComponent';
import { IShipmentFromWarehouse } from './types';
import FilterSelectDeliveryService from '../../components/FilterComponents/FilterSelectDeliveryService/FilterSelectDeliveryService';

const ParcelsFromWarehouse = ({ filterTabs, type }: IShipmentFromWarehouse) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const itemsLimit = localStorage.getItem(LOCAL_STORAGE_KEYS.ITEMS_LIMIT);

  const warehouseId = localStorage.getItem(
    LOCAL_STORAGE_KEYS.WAREHOUSE_ID_ORDER,
  );

  const isWithLabel = type === Warehouse_Shipment_Order_Type.WithBuyLabel;

  const [shipmentOrders, setShipmentOrders] = useState<
    WarehouseShipmentOrder[] | null
  >(null);
  const [isOpenSendParcel, setOpenSendParcel] = useState(false);
  const [orderId, setOrderId] = useState('');
  const [isOpenConfirmCancel, setIsOpenConfirmCancel] = useState(false);
  const [orderIdToCancel, setOrderIdToCancel] = useState<number | null>(null);

  const { t } = useTranslation();

  const [filter, setFilter] = useState<IFilters>({
    paymentStatusId: '',
    deliveryStatusId: 0,
    search: filterTabs?.search,
    page: 1,
    itemsLimit: itemsLimit ? +itemsLimit : LIMIT_ITEM_PAGE,
    warehouseId: warehouseId ? warehouseId : '',
    deliveryService: '' as DeliveryService,
  });

  const { data: currentUserData } = useGetCurrentUserQuery();

  const role = currentUserData?.currentUser?.role;

  const [getWarehouseShipmentOrdersLazyQuery, { data, loading }] =
    useGetWarehouseShipmentOrdersLazyQuery({
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
    });

  const [
    cancelWarehouseShipmentOrderMutation,
    { loading: cancelWarehouseShipmentOrderLoading },
  ] = useCancelWarehouseShipmentOrderMutation();

  const { data: addOptionsData, loading: addOptionsLoading } =
    useGetWarehouseShipmentOrderAdditionalOptionsQuery({
      fetchPolicy: 'network-only',
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
    });

  useEffect(() => {
    if (!data?.getWarehouseShipmentOrders?.rows) return;

    data?.getWarehouseShipmentOrders?.rows &&
      setShipmentOrders(
        data?.getWarehouseShipmentOrders?.rows as WarehouseShipmentOrder[],
      );
  }, [data]);

  useEffect(() => {
    setFilter((prev) => ({ ...prev, search: filterTabs?.search }));
    // eslint-disable-next-line
  }, [filterTabs]);

  useEffect(() => {
    getWarehouseShipmentOrdersLazyQuery({
      variables: {
        data: {
          offset: (filter.page - 1) * filter.itemsLimit!,
          limit: filter.itemsLimit,
          search: filter.search,
          ...(filter.dateFilter !== DEPARTURE_DATE_FILTER.ALL_TIME && {
            dateFilter: Number(filter.dateFilter),
          }),
          ...(filter.paymentStatusId && {
            paymentStatusId: filter.paymentStatusId as string,
          }),
          ...(filter.deliveryStatusId && {
            deliveryStatusId: filter.deliveryStatusId.toString(),
          }),
          ...(filter.deliveryService && {
            deiveryService: filter.deliveryService,
          }),
          warehouseId: filter.warehouseId,
          type,
        },
      },
    });
    // eslint-disable-next-line
  }, [filter]);

  const cancelWarehouseShipmentOrderHandler = (id: number) => {
    cancelWarehouseShipmentOrderMutation({
      variables: { id: String(id) },
      onCompleted: (data) => {
        if (data) {
          enqueueSnackbar(t('app.canceled'), { variant: 'success' });
        }
        setIsOpenConfirmCancel(false);
        setOrderIdToCancel(null);
      },
      onError: (error) => {
        if (error) {
          enqueueSnackbar(error.message, { variant: 'error' });
        }
      },
      refetchQueries: [QUERY_WAREHOUSE_SHIPMENT_ORDERS],
    });
  };

  const sendWarehouseSipmentOrderHandler = (id: string) => () => {
    setOpenSendParcel(true);
    setOrderId(id);
  };

  return (
    <>
      <div className={classes.filters}>
        {role === 'SUPER_ADMIN' ? (
          <FilterSelectWarehouse
            value={filter.warehouseId as string}
            setFilter={setFilter}
          />
        ) : null}
        <FilterSelectDepartureDate
          value={filter.dateFilter}
          setFilter={setFilter}
        />
        <FilterSelectDeliveryStatus
          value={filter.deliveryStatusId}
          setFilter={setFilter}
        />
        <FilterSelectPaymentStatus
          value={filter.paymentStatusId as string}
          setFilter={setFilter}
        />
        <FilterSelectLimitItems
          value={filter.itemsLimit as number}
          setFilter={setFilter}
          localStorageItemsLimitKey={LOCAL_STORAGE_KEYS.ITEMS_LIMIT}
        />
        <FilterSelectDeliveryService
          value={filter.deliveryService}
          setFilter={setFilter}
        />
      </div>
      <TableContainer component={Paper}>
        <Table arial-label={t('app.shipmentTable')}>
          <TableHead>
            <TableRow>
              {SHIPMENT_FROM_WAREHOUSE_TABLE_CELLS_ARRAY.map((cell) => (
                <TableCell align='center'>{t(cell)}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableBodyRequestHandler
              dataLength={shipmentOrders?.length as number}
              colSpan={SHIPMENT_FROM_WAREHOUSE_TABLE_CELLS_ARRAY.length}
              loadingText={t('app.shipments').toLowerCase()}
              loading={loading || addOptionsLoading}
            >
              {shipmentOrders
                ? shipmentOrders?.map((order) => {
                    return (
                      <ShipmentFromWarehouseTableRowComponent
                        key={order.id}
                        order={order}
                        setOrderIdToCancel={setOrderIdToCancel}
                        setIsOpenConfirmCancel={setIsOpenConfirmCancel}
                        isWithLabel={isWithLabel}
                        sendWarehouseSipmentOrderHandler={
                          sendWarehouseSipmentOrderHandler
                        }
                        wrappers={
                          addOptionsData
                            ?.getWarehouseShipmentOrderAdditionalOptions
                            ?.wrappers
                        }
                      />
                    );
                  })
                : null}
            </TableBodyRequestHandler>
          </TableBody>
        </Table>
      </TableContainer>

      <PaginationComponent
        count={data?.getWarehouseShipmentOrders?.count}
        limit={filter?.itemsLimit as number}
        currentPage={filter.page}
        setFilter={setFilter}
      />

      <CancelWarehouseShipmentOrderModal
        orderIdToCancel={orderIdToCancel}
        isOpenConfirmCancel={isOpenConfirmCancel}
        setIsOpenConfirmCancel={setIsOpenConfirmCancel}
        setOrderIdToCancel={setOrderIdToCancel}
        cancelWarehouseShipmentOrderHandler={
          cancelWarehouseShipmentOrderHandler
        }
        cancelWarehouseShipmentOrderLoading={
          cancelWarehouseShipmentOrderLoading
        }
      />

      <SendWarehouseShipmentOrder
        isOpenSendParcel={isOpenSendParcel}
        setOpenSendParcel={setOpenSendParcel}
        id={orderId}
        isWithLabel={isWithLabel}
      />
    </>
  );
};

export default ParcelsFromWarehouse;
