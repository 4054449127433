import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  mainTitle: {
    marginBottom: 32,

    [theme.breakpoints.down('sm')]: {
      fontSize: 23,
      marginBottom: 15,
    },
  },
  tableTitle: {
    textAlign: 'center',
    margin: '15px 0px',
  },
  itemInfo: {
    fontSize: 18,
  },
  root: {
    marginTop: 30,
    paddingTop: 30,
    borderTop: '1px solid #e0e0e0',

    '&:first-child': {
      marginTop: 0,
      paddingTop: 0,
      border: 'none',
    },
  },
  packageItem: {
    padding: '20px',
    borderRadius: '10px',
    border: '2px solid transparent',
    marginBottom: '32px',

    '&:hover': {
      backgroundColor: '#dcdcdc',
      border: '2px solid brown',
    },
  },
  inputLabel: {
    maxWidth: '280px',
    marginBottom: '30px',
  },
  inputLabelPackage: {
    maxWidth: '174px',
  },
  inputLabelOperation: {
    maxWidth: '360px',
  },
  boxModalButtons: {
    margin: '30px 0 0',
    display: 'flex',
    justifyContent: 'space-around',
  },
  btnIcon: {
    float: 'right',
    marginLeft: 5,
    lineHeight: '30px',
  },
  linkIcon: {
    fill: '#58a6ff',
    verticalAlign: 'middle',
  },
  warehouseSelect: {
    marginLeft: '16px',
    width: '226px',
  },
  wrapButtons: {
    display: 'flex',
    flexDirection: 'row',
    padding: '30px 16px 10px',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  actionIcon: {
    paddingRight: '5px',
    fontSize: '20px',
    alignSelf: 'flex-start',
  },
  btnOperation: {
    marginTop: '24px',
  },
  image: {
    width: '338px',
    height: '338px',
  },
}));

export default styles;
