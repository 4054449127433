import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  boxWrapper: {
    display: 'flex',
    justifyContent: 'start',
    gap: '6px',
    flexWrap: 'wrap',
  },
  boxComment: {
    fontSize: '12px',
    backgroundColor: '#5cb85c',
    padding: '1px',
    borderRadius: '3px',
    color: '#fff',
  },
  boxGift: {
    fontSize: '12px',
    backgroundColor: '#428bca',
    padding: '1px',
    borderRadius: '3px',
    color: '#fff',
  },
  boxPackaging: {
    fontSize: '12px',
    backgroundColor: '#f0ad4e',
    padding: '1px',
    borderRadius: '3px',
    color: '#fff',
  },
  boxGiftWrap: {
    fontSize: '12px',
    backgroundColor: '#ff0000',
    padding: '1px',
    borderRadius: '3px',
    color: '#fff',
  },
}));
