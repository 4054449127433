import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStyles from '../styles';
import { filterSelectDepartureDateOptions } from './options';
import { BoxCentered } from '../../../BoxCentered/BoxCentered';
import { IDashboardFilters } from '../../../../utils/types';
import EventIcon from '@material-ui/icons/Event';
import moment from 'moment';
import { DatePicker } from '@material-ui/pickers';
import {
  DASHBOARD_DEPARTURE_DATE_FILTER,
  handleDashboardSelectDate,
} from '../../../../utils/helpers';

interface IFilterSelectDashboardDepartureDate {
  value: IDashboardFilters;
  setFilter: React.Dispatch<React.SetStateAction<IDashboardFilters>>;
}

const FilterSelectDashboardDepartureDate = ({
  value,
  setFilter,
}: IFilterSelectDashboardDepartureDate) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const onChangeHandler = (
    e: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
  ) => {
    handleDashboardSelectDate(e, value, setFilter);
  };

  return (
    <Box className={classes.wrapper}>
      <EventIcon className={classes.icon} />
      <FormControl className={classes.filtersItemSelect}>
        <BoxCentered pl={2} pr={2}>
          <Select
            displayEmpty
            disableUnderline
            value={value.dateSelectValue}
            onChange={onChangeHandler}
          >
            {filterSelectDepartureDateOptions.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
                disabled={!option.value && option.value !== 0}
              >
                {option.nDays
                  ? t(option.title, { value: option.nDays })
                  : t(option.title)}
              </MenuItem>
            ))}
          </Select>
        </BoxCentered>
      </FormControl>
      {value.dateSelectValue === DASHBOARD_DEPARTURE_DATE_FILTER.RANGE && (
        <>
          <FormControl className={classes.filtersItemSelect}>
            <BoxCentered>
              <InputLabel
                className={classes.dateLabel}
                shrink={false}
                htmlFor='input-date-from'
              >
                {t('app.dateFrom')}:
              </InputLabel>
              <DatePicker
                id='input-date-from'
                placeholder={t('app.chooseDate')}
                value={value.dateFilter.from || null}
                format='YYYY.MM.DD'
                onChange={(date) => {
                  setFilter((state) => {
                    return {
                      ...state,
                      dateFilter: {
                        ...state.dateFilter,
                        from: date?.format('YYYY-MM-DD'),
                        to: moment(date).add(31, 'days')?.format('YYYY-MM-DD'),
                      },
                    };
                  });
                }}
                maxDate={moment()}
              />
            </BoxCentered>
          </FormControl>
          <FormControl className={classes.filtersItemSelect}>
            <Box display='flex' alignItems='center' justifyContent='center'>
              <InputLabel
                className={classes.dateLabel}
                shrink={false}
                htmlFor='input-date-to'
              >
                {t('app.dateTo')}
              </InputLabel>
              <DatePicker
                id='input-date-to'
                placeholder={t('app.chooseDate')}
                value={value.dateFilter.to || null}
                format='YYYY.MM.DD'
                onChange={(date) => {
                  setFilter((state) => {
                    return {
                      ...state,
                      dateFilter: {
                        ...state.dateFilter,
                        to: date?.format('YYYY-MM-DD'),
                      },
                    };
                  });
                }}
                minDate={value.dateFilter.from}
                maxDate={
                  moment(value.dateFilter.from).add(31, 'days') > moment()
                    ? moment()
                    : moment(value.dateFilter.from).add(31, 'days')
                }
              />
            </Box>
          </FormControl>
        </>
      )}
    </Box>
  );
};

export default FilterSelectDashboardDepartureDate;
