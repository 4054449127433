import { TableCell, TableRow } from '@material-ui/core';
import React from 'react';
import { ITableListRow } from '../types';
import { TableHeadCellsEnums } from '../../../utils/constants';
import { Link } from 'react-router-dom';
import { TRoutes } from '../../../utils/helpers';
import noPhotoIconUrl from '../../../assets/img/icon_no_photo.png';

export const TableListRow = ({ row, headCells, number }: ITableListRow) => {
  return (
    <TableRow>
      {headCells.map((column) => {
        const value = row[column.name];

        if (column.name === TableHeadCellsEnums.ID)
          return (
            <TableCell key={column.label} align='center'>
              <Link
                to={`${TRoutes.WAREHOUSE_MY_PRODUCT_DETAILS_WITHOUT_ID}${value}`}
              >
                {row.titleId}
              </Link>
            </TableCell>
          );
        if (column.name === TableHeadCellsEnums.B2B_PRODUCTS_QUANTITY)
          return (
            <TableCell key={column.label} align='center'>
              {value}
            </TableCell>
          );

        if (column.name === TableHeadCellsEnums.PHOTO)
          return (
            <TableCell key={column.label} align='center'>
              <img
                src={(value as string) || noPhotoIconUrl}
                alt={`photo_${value}`}
                style={{ width: '38px', height: '38px' }}
                // className={classes.icon}
              />
            </TableCell>
          );

        return (
          <TableCell key={column.label} align='center'>
            {column.name === TableHeadCellsEnums.NUMBER ? number : value}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
