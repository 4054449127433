import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  filters: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: '24px',
    marginTop: '36px',
    gap: '24px',

    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    },
  },
  boxesWrapper: {
    display: 'flex',
    flexDirection: 'row',
    gap: '38px',
  },
  graphWrapper: {
    // display: 'flex',
    // gap: '38px',
  },
  pageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: '30px',
  },
}));

export default styles;
